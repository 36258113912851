import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import CumulativeChart from './CumulativeChart';
import WeeklyChart from './WeeklyChart';

const axios = require('axios').default;

function CustomerDefectDataView(props) {
  const { release, jwtToken, baseApiUri, getUrl } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);


  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { headers })
        .then(response => {
          setReport(response.data);
          setReportError(response.data.error);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        })
    }
  }, [release, getUrl, jwtToken, baseApiUri]);

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError />
  if (reportNotFound) return <ReportNotFound />

  return (
    <React.Fragment>
      <ViewTitle title='Customer Defect Data'/>
      {
        report && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CumulativeChart {...report} />
            </Grid>
            <Grid item xs={12} md={6}>
              <WeeklyChart {...report} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}


function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
}

export default connect(mapStateToProps)(CustomerDefectDataView)
