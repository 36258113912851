import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import BackendError from '../../misc/BackendError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import ArrivalClosureChart from './ArrivalClosureChart';
import DatesTable from './DatesTable';

const axios = require('axios').default;

function ExecutiveView(props) {
  const { release, jwtToken, baseApiUri, getUrl, filtersEnabled, componentFilter, severityFilter } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const componentIds = filtersEnabled ? componentFilter.join(',') : undefined;
  const severityIds = filtersEnabled ? severityFilter.join(',') : undefined;

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setBackendError(false);
      setReportNotFound(false);

      const params = filtersEnabled ? { 
        component_ids: componentIds, 
        severity_ids: severityIds,
      } : {};

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { params, headers })
        .then(response => {
          setReport(response.data);
          setReportError(response.data.error);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, getUrl, filtersEnabled, componentIds, severityIds, jwtToken, baseApiUri]);


  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError error={report ? report.error : ''} />
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  return (
    <React.Fragment>
      <ViewTitle title='Arrival / Closure View'/>
      {
        report && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12}>
              <ArrivalClosureChart {...report} />
            </Grid>
            <Grid item xs={8} md={5}>
              <DatesTable {...report} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    componentFilter: state.common.componentFilter,
    severityFilter: state.common.severityFilter,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    filtersEnabled: state.common.showDefectFilter,
  };
} 

export default connect(mapStateToProps)(ExecutiveView)
