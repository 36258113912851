import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import BackendError from '../../misc/BackendError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import EarlyPredictionChart from './EarlyPredictionChart';
import DatesTable from './DatesTable';
import CumulativeChart from '../executive_view/CumulativeChart';
import BacklogChart from '../executive_view/BacklogChart';

const axios = require('axios').default;

function EarlyPredictionView(props) {
  const { release, jwtToken, baseApiUri, getUrl, warnOnError } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  const [combinedReport, setCombinedReport] = React.useState();
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const buildCombinedReport = reportIn => {
    if (reportIn.ppm_prediction) {
      setCombinedReport({
        ...reportIn,
        arrival: {
          ...reportIn.ppm_prediction.arrival,
          actual: reportIn.arrival.actual,
          actual_processed: reportIn.arrival.actual_processed,
        },
        backlog: {
          ...reportIn.ppm_prediction.backlog,
          actual: reportIn.backlog.actual,
          actual_processed: reportIn.backlog.actual_processed,
        },
        closure: {
          ...reportIn.ppm_prediction.closure,
          actual: reportIn.closure.actual,
          actual_processed: reportIn.closure.actual_processed,
        },
      });
    }
  };

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setCombinedReport(undefined);
      setReportError(false);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { headers })
        .then(response => {
          setReport(response.data);
          buildCombinedReport(response.data);
          setReportError(Boolean(response.data.error));
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response && err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, getUrl, jwtToken, baseApiUri]);
  
  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError error={report ? report.error : ''} warnOnError={warnOnError} />
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  return (
    <React.Fragment>
      <ViewTitle title='Early Prediction View'/>
      {
        report && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12}>
              <EarlyPredictionChart {...report} />
            </Grid>

            {
              combinedReport && (
                <>
                  <Grid item xs={12} md={6}>
                    <CumulativeChart {...combinedReport} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BacklogChart {...combinedReport} />
                  </Grid>
                </>
              )
            }

            <Grid item xs={9} md={6}>
              <DatesTable {...report} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(EarlyPredictionView)
