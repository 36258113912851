import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ViewTitle from '../../../common/layout/ViewTitle';
import InfoButton from '../../../common/data_display/InfoButton';
import { formatDateTime } from '../../../common/data_display/utils';
import ReportNotFound from '../../misc/ReportNotFound';
import BackendError from '../../misc/BackendError';
import PredictionError from '../../misc/PredictionError';
import ReportRunning from '../../misc/ReportRunning';
import { PredictionStatus } from "../consts";
import CumulativeChart from './CumulativeChart';
import BacklogChart from './BacklogChart';
import FixTable from './FixTable';
import FindTable from './FindTable';
import DefectTable from './DefectTable';
import DeliveryReadyTable from './DeliveryReadyTable';
import ExecutiveInfoModal from './ExecutiveInfoModal';
import DownloadButton from './DownloadButton';

const axios = require('axios').default;

function ExecutiveView(props) {
  const { release, jwtToken, baseApiUri, getUrl, filtersEnabled, componentFilter, severityFilter } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [loadCount, setLoadCount] = React.useState(0)

  const [report, setReport] = React.useState();
  const [status, setStatus] = React.useState();
  const [timestamp, setTimestamp] = React.useState();
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);

  const componentIds = filtersEnabled ? componentFilter.join(',') : undefined;
  const severityIds = filtersEnabled ? severityFilter.join(',') : undefined;

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setBackendError(false);
      setReportNotFound(false);

      const params = filtersEnabled ? { 
        component_ids: componentIds, 
        severity_ids: severityIds,
      } : {};

      const headers = { Authorization: jwtToken };

      axios.get(`${baseApiUri}${getUrl(release)}`, { params, headers })
        .then(response => {
          setReport(response.data);
          setStatus(response.data.status);
          setTimestamp(response.data.timestamp);

          if (response.data.status === PredictionStatus.RUNNING) {
            setTimeout(() => setLoadCount(loadCount + 1), 2000);
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, getUrl, filtersEnabled, componentIds, severityIds, jwtToken, baseApiUri, loadCount]);


  // --------------------------- Event Handlers ---------------------------- //
  // ----------------------------------------------------------------------- //
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleExcelExport = () => {
    const url = `${baseApiUri}${getUrl(release)}` + '/download';
    
    const params = filtersEnabled ? { 
        component_ids: componentFilter.join(','), 
        severity_ids: severityFilter.join(','),
      } : {};

    const headers = { Authorization: jwtToken };

    axios.get(url, {params, headers, responseType: "blob"})
      .then(response => {
        const blob = response.data;
        const contDisposition = response.headers["content-disposition"];
        const filename = contDisposition.split("=")[1];

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
      });

    return false;
  }

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  if (status === PredictionStatus.RUNNING) return <ReportRunning timestamp={timestamp} />
  console.log(status);
  const title = `Executive Summary ${timestamp ? `(${formatDateTime(timestamp)})` : ""}`

  return (
    <React.Fragment>
      <ViewTitle title={title} prediction_type={report ? report.prediction_type : undefined}>
        <InfoButton onClick={handleModalOpen} />
        <DownloadButton onClick={handleExcelExport} />
      </ViewTitle>

      {status === PredictionStatus.ERROR && (
        <PredictionError error={report?.error} />
      )}

      {
        report && (
          <Grid container spacing={2} justify='center'>
            <Grid item xs={12} lg={6}>
              <DeliveryReadyTable {...report} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DefectTable {...report} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FindTable {...report} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FixTable {...report} />
            </Grid>
            <Grid item xs={12} md={6}>
              <CumulativeChart {...report} />
            </Grid>
            <Grid item xs={12} md={6}>
              <BacklogChart {...report} />
            </Grid>
          </Grid>
        )
      }
      
      <ExecutiveInfoModal open={modalOpen} onClose={handleModalClose} />
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    componentFilter: state.common.componentFilter,
    severityFilter: state.common.severityFilter,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    filtersEnabled: state.common.showDefectFilter,
  };
} 

export default connect(mapStateToProps)(ExecutiveView)
