export const cumulateDatapoints = (defects) => {
  const cumulatedDatapoints = [];

  if (defects) {
    for(var i=0; i<defects.length; i++) {
      cumulatedDatapoints.push({
        'date': defects[i].date,
        'value': defects[i].value
      });

      if (i > 0)  {
        cumulatedDatapoints[i]['value'] += cumulatedDatapoints[i-1]['value'];
      }
    }
  }

  return cumulatedDatapoints;
}


export const formatDatapoint = (obj) => {
  obj.date = new Date(obj.date);
  return obj;
};

export const formatNumber = (x) => x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;

export const formatDate = function (x) {
  if (!x) {
    return x;
  }

  const dateValue = x instanceof Date ? x : new Date(x);

  if (isNaN(dateValue)) {
    return x;
  }

  return dateValue.toISOString().substring(0, 10);
}

export const formatDateTime = function (x) {
  if (!x) {
    return x;
  }

  const dateValue = x instanceof Date ? x : new Date(x);

  if (isNaN(dateValue)) {
    return x;
  }

  return dateValue.toLocaleString();
}

export const formatString = function() {
  var format = arguments[0];
  var args = Array.prototype.slice.call(arguments, 1);
  return format.replace(/{(\d+)}/g, function(match, number) { 
    return typeof args[number] != 'undefined'
      ? args[number] 
      : match
    ;
  });
}

export const getMinDataDate = (dataSets, d1Date) => {
  const firstDates = dataSets.filter(x => x && x.data && x.data.length).map(x => x.data[0].date);
  if (d1Date) {
    firstDates.push(d1Date);
  }

  return getMinDate(firstDates);
};

export const getMaxDataDate = (dataSets, d2Date) => {
  const lastDates = dataSets.filter(x => x && x.data && x.data.length).map(x => x.data[x.data.length - 1].date);
  if (d2Date) {
    lastDates.push(d2Date);
  }

  return getMaxDate(lastDates);
};

export const getMinDate = (dates) => {
  return new Date(Math.min(...dates));
};

export const getMaxDate = (dates) => {
  return new Date(Math.max(...dates));
};

export const parseDate = (dateStr) => {
  if (!dateStr) {
    return undefined;
  }

  const date = new Date(dateStr);

  if (isNaN(date)) {
    return undefined;
  }

  return date;
};

export const daysBetween = (dateA, dateB) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  
  return Math.round((dateA - dateB) / oneDay);
};

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}