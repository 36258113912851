import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },  
  content: {
    paddingTop: 12,
  },
}));

export default function ReleasesSelector(props) {
  const classes = useStyles();
  const { releaseReports, setReleaseReports } = props;

  const [allChecked, setAllChecked] = React.useState(true);
  const [indeterminate, setIndeterminate] = React.useState(false);

  const handleParentChange = (event) => {
    setReleaseReports(releaseReports.map(x => {
      return {
        ...x,
        visible: event.target.checked,
      };
    }));
  };

  const handleChange = (event, index) => {
    const releaseReportsCopy = releaseReports.map(x => {
      return {
        ...x,
      };
    });

    releaseReportsCopy[index].visible = event.target.checked;

    setReleaseReports(releaseReportsCopy);
  };

  React.useEffect(() => {
    setAllChecked(releaseReports.every(x => x.visible));
    setIndeterminate(releaseReports.some(x => x.visible) && releaseReports.some(x => !x.visible));
  }, [releaseReports]);

  return <Card>
    <CardContent className={classes.content}>
      <FormControlLabel
        label="Select All"
        control={
          <Checkbox
            checked={allChecked}
            indeterminate={indeterminate}
            onChange={handleParentChange}
          />
        }
      />
      <Divider/>
      { releaseReports.map((releaseReport, index) => {
        return (
          <>
            <FormControlLabel
              label={
                <div className={classes.checkboxLabel}>
                  <span>{ releaseReport.name }</span>
                  &nbsp;
                  <RemoveIcon style={{fill: releaseReport.color}} />
                </div>
              }
              control={
                <Checkbox
                  checked={releaseReport.visible}
                  onChange={event => handleChange(event, index)}
                />
              }
            />
            <br />
          </>
      )})}
    </CardContent>
  </Card>;
}
