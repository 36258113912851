import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ErrorIcon from '@material-ui/icons/Error';

export default function ReportError(props) {
  const { error, warnOnError } = props;

  const errorText = warnOnError 
    ? 'Invalid Project/Release Combination'
    : 'Prediction Error';

  return (
    <div style={{ borderRadius: 8, color: 'grey', maxWidth: 800, padding: 24, margin: '0 auto', marginTop: '24px', textAlign: 'center' }}>
      { 
        warnOnError 
          ? (<ErrorIcon style={{ fontSize: 190 }}/>)
          : (<ReportProblemIcon style={{ fontSize: 190 }}/>)
      }

      <Typography style={{ fontSize: 24 }}>
        <b>{ errorText }</b> <br/>
      </Typography>

      <Typography style={{ fontSize: 16 }}>
        <b>{error}</b>
      </Typography>

      <br/>
   </div>
  )
}
