import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { secondaryMain } from '../../../constants/colors';
import MpmNested from './MpmNested';
import SakuraCard from '../../../common/layout/SakuraCard';
import { SubmitButton } from '../../../common/forms/buttons';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '12px 24px',
  },
  cardTitle: {
    marginBottom: 12,
    fontSize: 12,
    // fontWeight: 'bold',
    // textDecoration: 'underline',
    color: secondaryMain,
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
  success: {
    color: 'seagreen',
    fontWeight: 'bold',
  },
}));

export default function PrepMpmConfig(props) {
  const classes = useStyles();
  const { release, jwtToken, baseApiUri } = props;

  const getUrl = (r) => `${baseApiUri}/releases/${r.id}/config/prep-mpm`;


  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [config, setConfig] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [successMessage, setSuccessMessage] = React.useState();
  const [disabled, setDisabled] = React.useState(true);


  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (!release) return;

    setConfig(undefined);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);

    const headers = { Authorization: jwtToken };

    return axios.get(getUrl(release), { headers })
      .then(response => {
        setConfig(response.data);
        setDisabled(false);
      })
      .catch(err => {
        console.log(err);
      })
  }, [release, jwtToken]);


  // --------------------------- Event Handlers ---------------------------- //
  // ----------------------------------------------------------------------- //
  const handlePrepMpmChange = (field) => (e) => setConfig({
    ...config,
    [field]: Number(e.target.value),
  });

  const handleSubmit = (e) => {
    setErrorMessage(undefined);
    setSuccessMessage(undefined);

    setDisabled(true);
    const headers = { Authorization: jwtToken };

    return axios.put(getUrl(release), config, {headers})
      .then(response => {
        setConfig(response.data);
        setDisabled(false);
        setSuccessMessage('Config updated successfully.');
      })
      .catch(err => {
        setErrorMessage('Could not update configuration.');
        setDisabled(false);
      })
  };


  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  return (
    <form onSubmit={ (e) => { handleSubmit(); e.preventDefault(); } }>
      { config && (
        <SakuraCard title='Prep-MPM Configuration'>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography className={classes.cardTitle}>Prep-MPM Config</Typography>
              <MpmNested config={config} onChange={handlePrepMpmChange} disabled={disabled} />
            </Grid>
          </Grid>

          <br/>
          <Divider />
          <br/>

          <Typography className={classes.error} align='center'>{errorMessage}</Typography>
          <Typography className={classes.success} align='center'>{successMessage}</Typography>

          <br/>

          <Box display="flex" justifyContent="center" alignItems="center">
            <SubmitButton disabled={disabled} />
          </Box>
        </SakuraCard>
      )}
    </form>
  )
}
