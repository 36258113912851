import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import ViewTitle from '../../../common/layout/ViewTitle';
import InfoModal from '../../../common/data_display/InfoModal';
import ReleaseConfig from './ReleaseConfig';
import DefectUploader from './DefectUploader';
import EffortUploader from './EffortUploader';
import Notes from './Notes';
import { set } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    alignContent: 'flex-start',
  },
  modalButtonContainer: {
    flex: '0 0 100%',
    marginBottom: -12,
  },
  closeModalButton: {
    width: 200,
  },
}));

function Config(props) {
  const classes = useStyles();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => { 
    sessionStorage.setItem('hasSeenDemoConfigModal', 'true');
    setSeenDemoConfigModal(true);
    setModalOpen(false);
  } 

  const [hasSeenDemoConfigModal, setSeenDemoConfigModal] = useState(sessionStorage.getItem('hasSeenDemoConfigModal') === 'true');

  React.useEffect(() => {
    const startsWithDemo = location.pathname.startsWith('/demo');
    if (startsWithDemo && !hasSeenDemoConfigModal) {
      setModalOpen(true);
    }
  }, [location, hasSeenDemoConfigModal]);

  const closeModalProps = {
    color: "secondary",
    variant: "contained",
    type: "submit",
    className: classes.closeModalButton,
    onClick: handleModalClose
  };

  return (
    <React.Fragment>
      <ViewTitle title='Release Configuration' />
      { props.release && ( 
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={4}>
            <ReleaseConfig {...props} />
          </Grid>
          
          <Grid className={classes.flexContainer} container item spacing={2} sm={12} md={6} lg={8}>
            <Grid item sm={12} lg={6}>
              <DefectUploader />
            </Grid>

            <Grid item sm={12} lg={6}>
              <EffortUploader />
            </Grid>

            <Grid item sm={12}>
              <Notes />
            </Grid>
          </Grid>
        </Grid>
      )}

      <InfoModal title="Demo Mode Alert" open={modalOpen} onClose={handleModalClose}>
        <div>
          <p>
            Unfortunately, user inputs are not available in Demo mode. Ready to unlock full access? 
          </p>
          <p>
            <a href={process.env.REACT_APP_AUTH_URL}>Sign In or Sign Up!</a>
          </p>
          <div align='center' className={classes.modalButtonContainer}>
            <Button {...closeModalProps}>
              {`Continue`}
            </Button>
          </div>
        </div>
      </InfoModal>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    releaseList: state.common.releaseList,
    isAdmin: state.common.isAdmin,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    company: state.common.company,
  };
} 

export default connect(mapStateToProps)(Config)
