import React from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import { Toolbar, Menu, Typography, MenuItem } from '@mui/material';
import uniqid from 'uniqid';


const useStyles = makeStyles((theme) => ({
  root: {
    float: "right",
    marginRight: 12,
    color: 'white',
    borderRadius: 16,
    fontSize: 24,
    '&:hover': {
      cursor: 'pointer'
    },
  },
  link: {
    textDecoration: 'none',
    padding: '2 8',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
  },
}));

export default function UserDropdown(props) {
  const classes = useStyles();
  const [id, setId] = React.useState(uniqid());

  const handleClick = async () => {
    try {
        await Auth.signOut();
        window.location = process.env.REACT_APP_AUTH_URL;
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => (
        <React.Fragment>
          <AccountCircleSharpIcon className={classes.root} aria-controls={id} aria-haspopup="true" aria-expanded={popupState.isOpen} {...bindHover(popupState)} />
          <HoverMenu
            {...bindMenu(popupState)}
            id={id}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem><a className={classes.link} href={`${process.env.REACT_APP_LANDING_URL}/account`}>Membership</a></MenuItem>
            <MenuItem onClick={handleClick}><Typography className={classes.link}>Sign Out</Typography></MenuItem>
          </HoverMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
