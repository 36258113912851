import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import store from '../../../store';
import {sidebarClosedWidth} from '../../../constants/dimensions';
import { backgroundLight, darkGrey, primaryMain, secondaryMain } from '../../../constants/colors';
import { getProjectList, getReleaseList, setRelease, setProject, setComponentFilter, setSeverityFilter} from '../../redux/actions';
import DefectFilter from './DefectFilter';
import DropdownSelect from './DropdownSelect';
import CompanySelect from './CompanySelect';
import UserDropdown from './UserDropdown';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '72px',
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    overflow: 'visible',
    background: primaryMain,
  },
  topbar: {
    minHeight: '36px',
    padding: '4px 12px',
    overflow: 'visible',
    borderBottom: `1px solid ${'black'}`,
  },
  image: {
    height: 32,
    padding: '4px 8px',
    marginTop: 6,
  },
  right: {
    marginLeft: 'auto',
  },
  bottombar: {
    minHeight: '36px',
    padding: '0 24px',
    paddingLeft: sidebarClosedWidth,
    background: 'white',
    borderBottom: `1px solid ${darkGrey}`,
  },
  logo: {
    height: '28px',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  demoUserInfo: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
  },
  demoTypography: {
    color: secondaryMain,
    fontWeight: 'bold',
    fontSize: 28,
    alignItems: 'center',
    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
  },  
  freeTrialLink: {
    marginLeft: 10,
    padding: '6px 4px 4px 4px',
    fontSize: 12,
    fontWeight: 'bold',
    color: backgroundLight,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    },
  },
  freeTrialDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  freeTrialIcon: {
    height: 16,
    verticalAlign: 'text-bottom',
  },
}));

function CompanySection(props) {
  const classes = useStyles();
  const {
    demoEnabled,
    company,
    companyList,
  } = props;

  if (demoEnabled) {
    return (
      <div className={classes.demoUserInfo}>
        <Typography className={classes.demoTypography}>DEMO</Typography>
        <div className={classes.freeTrialDiv}>
          <a href={process.env.REACT_APP_LANDING_URL} className={classes.freeTrialLink}>
            Landing Page <ExitToAppIcon className={classes.freeTrialIcon} />
          </a>
        </div>
      </div>
    );
  }
  
  if (company && companyList) {
    return (
      <React.Fragment>
        <CompanySelect company={company} companyList={companyList} />
      </React.Fragment>
    );
  }

  return null;
}

function Header(props) {
  const classes = useStyles();

  const {
    company,
    project,
    release,
    companyList,
    projectSelector,
    releaseSelector,
    releaseNull,
    componentFilterGroup,
    severityFilterGroup,
    showDefectFilter,
    showReleaseSelector,
    isAuthenticated,
    demoEnabled,
  } = props;

  const projectOnUpdate = () => {
    store.dispatch(getProjectList());
  };

  const releaseOnUpdate = () => {
    store.dispatch(getReleaseList(project));
  };

  const projectOnChange = (e) => {
    const { value } = e.target;

    if (project.id !== value.id) {
      store.dispatch(setProject(value));
    }
  }

  const releaseOnChange = (e) => {
    const { value } = e.target;

    if (release.id !== value.id) {
      store.dispatch(setRelease(value));
    }
  }

  const componentsOnChange = (e) => {
    const { checked, value } = e.target;
    const { selected } = componentFilterGroup;

    const valueInt = parseInt(value);
    const newSelected = checked ? [valueInt] : [];
    // const selectedCopy = [...selected];

    // if (checked && !selectedCopy.includes(valueInt)) {
    //   selectedCopy.push(valueInt)
    // }

    // else if (!checked && selectedCopy.includes(valueInt)) {
    //   const index = selectedCopy.indexOf(valueInt);
    //   selectedCopy.splice(index, 1);
    // }

    store.dispatch(setComponentFilter(newSelected));
  }

  const severitiesOnChange = (e) => {
    const { checked, value } = e.target;
    const { selected } = severityFilterGroup;

    const valueInt = parseInt(value);
    const newSelected = checked ? [valueInt] : [];
    // const selectedCopy = [...selected];

    // if (checked && !selectedCopy.includes(valueInt)) {
    //   selectedCopy.push(valueInt)
    // }

    // else if (!checked && selectedCopy.includes(valueInt)) {
    //   const index = selectedCopy.indexOf(valueInt);
    //   selectedCopy.splice(index, 1);
    // }

    store.dispatch(setSeverityFilter(newSelected));
  }

  return (
    <AppBar elevation={2} className={classes.root}>
      <Box boxShadow={24} component={Toolbar} className={classes.topbar}>
          <a href='/'>
            <img alt='logo' className={classes.image} src={process.env.PUBLIC_URL + '/star.png'} /> 
          </a>

          <div className={classes.right}>
          <CompanySection {...props} />
          { !demoEnabled && isAuthenticated && 
            (
              <UserDropdown {...props} />
            )
          }
          </div>
          
      </Box>
      <Toolbar disableGutters className={classes.bottombar}>
        { company && projectSelector.value && (releaseSelector.value || releaseNull) && (
          <React.Fragment>
            <DropdownSelect
              {...projectSelector}
              isAuthenticated={isAuthenticated}
              onChange={projectOnChange}
              addUri={`/companies/${company.id}/projects`}
              getEditUri={(projectId) => `/projects/${projectId}`}
              onUpdate={projectOnUpdate}
              entityName='Project'
            />
            { showReleaseSelector && ( 
              <DropdownSelect
                {...releaseSelector}
                isAuthenticated={isAuthenticated}
                onChange={releaseOnChange} 
                addUri={`/projects/${project.id}/releases`}
                getEditUri={(releaseId) => `/releases/${releaseId}`}
                onUpdate={releaseOnUpdate}
                entityName='Release'
              />
            )}
          </React.Fragment>
        )}

        <span className={classes.toolbarButtons}>
          {(isAuthenticated || demoEnabled) && showDefectFilter && (
            <DefectFilter 
              componentFilterGroup={componentFilterGroup}
              severityFilterGroup={severityFilterGroup}
              componentsOnChange={componentsOnChange}
              severitiesOnChange={severitiesOnChange}
            />
          )}
        </span>
      </Toolbar>
    </AppBar>
  )
}

function mapStateToProps(state) {
  return {
    company: state.common.company,
    project: state.common.project,
    release: state.common.release,
    companyList: state.common.companyList,
    projectSelector: {
      label: 'Project',
      items: state.common.projectList,
      value: state.common.project,
    },
    releaseSelector: {
      label: 'Release',
      items: state.common.releaseList,
      value: state.common.release,
    },
    componentFilterGroup: {
      label: 'Components',
      items: state.common.componentList,
      selected: state.common.componentFilter,
    },
    severityFilterGroup: {
      label: 'Severities',
      items: state.common.severityList,
      selected: state.common.severityFilter,
    },
    releaseNull: state.common.releaseNull,
    showReleaseSelector: state.common.showReleaseSelector,
    showDefectFilter: state.common.showDefectFilter,
  };
} 

export default connect(mapStateToProps)(Header)
