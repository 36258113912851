import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import NumericInput from 'react-numeric-input';
import { Tooltip } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { secondaryMain, grey, primaryLightest } from '../../../constants/colors';
import { formatString } from '../../../common/data_display/utils';


// ------------------------------ Input Table ------------------------------ //
// ------------------------------------------------------------------------- //
const useInputTableStyles = makeStyles((theme) => ({
  loader: {
    margin: '0 auto',
  },
  action: {
    border: `1px solid ${grey}`,
  },
  actionContent: {
    padding: '12px 24px',
    paddingBottom: 6,
    background: primaryLightest,
  },
  actionTitle: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  input: {
    width: '80px',
    height: '30px',
    background: 'white',
    borderRadius: '4px!important',
  },
  divider: {
    marginBottom: 10,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: 6,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tooltip: {
    fontSize: 10,
  },
}));

const ActionInput = (props) => {
  const classes = useInputTableStyles();
  const handleChange = (newValue) => {
    const clamped = Math.min(Math.max(newValue, 0), props.maxValue);
    props.setValue(clamped);
  };

  const tooltipText = `Max value ${props.maxValue}`;

  return (
    <Grid item xs={12} md={6} container 
      direction="row"
      justifyContent="space-between"
      alignItems="center">
      <Grid item className={classes.inputLabel}>
        <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipText} aria-label={tooltipText} placement='right'>
          <span>
            {formatString(props.displayFormat, props.value)}
          </span>
        </Tooltip>
      </Grid> 

      <Grid item>
        <NumericInput 
          className={classes.input} 
          value={ props.value } 
          min={ 0 } 
          max={ props.maxValue } 
          step={ 1 } 
          onChange={handleChange}
          onValid={handleChange}
        />
      </Grid>
    </Grid>
  )
};

export default function InputTable(props) {
  const classes = useInputTableStyles();
  const { value, onValueChange, onValueChangeCommitted } = props;

  const options = [
    {
      displayFormat: 'Delay Release by {0} Weeks',
      maxValue: 7,
      value: value.delayValue,
      setValue: (val) => onValueChange({ delayValue: val })
    },
    {
      displayFormat: 'Add {0}% More Developers',
      maxValue: 20,
      value: value.developersValue,
      setValue: (val) => onValueChange({ developersValue: val })
    },
    {
      displayFormat: 'Add {0}% More Testers',
      maxValue: 20,
      value: value.testersValue,
      setValue: (val) => onValueChange({ testersValue: val })
    },
    {
      displayFormat: 'Reduce Content by {0}%',
      maxValue: 20,
      value: value.contentValue,
      setValue: (val) => onValueChange({ contentValue: val })
    }
  ];

  const submitButtonProps = {
    color: 'secondary',
    variant: 'contained',
    onClick: onValueChangeCommitted
  };

  return (
    <Card className={classes.action}>
      <CardContent className={classes.actionContent}>
        <Typography className={classes.actionTitle} gutterBottom variant="h3">
          How can we improve our software quality?
        </Typography>
        <Divider className={classes.divider} />

        <Grid container spacing={1}>
          {options.map((option) => ActionInput(option))}
        </Grid>

        <div className={classes.buttonContainer}>
          <Button {...submitButtonProps}>
            {`Submit`}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

