import React from 'react';
import 'date-fns';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';
import { primaryLightest } from '../../constants/colors';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: 16,
    marginTop: 0,
  },
  label: {
    fontWeight: 'bold',
  },
  input: {
    padding: '10px 14px',
    background: primaryLightest,
  },
  dateLabel: {
    fontWeight: 'bold',
    paddingLeft: 14,
  },
  dateInput: {
    padding: '10px 14px',
    background: primaryLightest,
    border: '1px solid #c4c4c4',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  selectLabel: {
    paddingLeft: 14,
    fontWeight: 'bold',
  },
  formControl: {
    width: '100%',
  },
  selectRoot: {
    background: primaryLightest,
    paddingBottom: '10px',
    paddingTop: '10px',
  },
});


export const NumberInput = (props) => {
  const classes = useStyles();
  const { label, value, onChange, required, disabled } = props;

  const inputLabelProps = {
    className: classes.label,
  };

  const inputProps = {
    className: classes.input,
  };

  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      type="number"
      variant="outlined"
      onChange={onChange}
      InputLabelProps={inputLabelProps}
      inputProps={inputProps}
      className={classes.root}
      required={required}
    />
  )
}


export const DateInput = (props) => {
  const classes = useStyles();
  const { label, value, onChange, required, disabled } = props;

  const inputLabelProps = {
    shrink: true,
    className: clsx(classes.label, classes.dateLabel),
  };

  const inputProps = {
    className: clsx(classes.input, classes.dateInput),
  };

  return (
    <DatePicker
      disabled={disabled}
      format="yyyy-MM-dd"
      label={label}
      views={["year", "month", "date"]}
      value={value ? value : null}
      onChange={onChange}
      InputLabelProps={inputLabelProps}
      inputProps={inputProps}
      className={classes.root}
      required={required}
      defaultValue={null}
    />
  )
}


export function ReleaseSelect(props) {
  const { value, options, onChange, label, disabled} = props;
  const classes = useStyles();

  const selectProps = {
    label: label,
    value: value,
    onChange: onChange,
    inputProps: {
      name: 'release',
      id: 'release-select',
    },
    classes: {
      root: classes.selectRoot,
    },
    disabled,
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Box mb={1}>
        <InputLabel className={clsx(classes.label, classes.releaseSelectLabel)} shrink={true} htmlFor="release-select" disabled={disabled}>{label}</InputLabel>
      </Box>
      <Select native {...selectProps}>
        <option aria-label="None" value={undefined} />
        {
          options.map((item) => (
            <option value={item.id}>
              {item.name}
            </option>
          )
        )}
      </Select>
    </FormControl>
  );
}
