import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { secondaryMain } from '../../constants/colors';


const useStyles = makeStyles((theme) => ({
  modal: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 480,
    outline: 'none',
    border: 'none',
    background: 'white',
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
  modalTitle: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  section: {
    paddingTop: 12,
  }
}));

const TextSection = (props) => {
  const classes = useStyles();
  const { title, text } = props;

  return (
    <Box className={classes.section}>
      <Typography variant='h3'>
        {title}
      </Typography>
      <Typography>
        {text}
      </Typography>
    </Box>
  )
}

export default function PopUpModal(props) {
  const classes = useStyles();
  const { title, textSections, children, open, onClose } = props;

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography className={classes.modalTitle} gutterBottom variant="h3">
              {title}
            </Typography>
            <Divider />
            { textSections && textSections.map((section) => <TextSection title={section.title} text={section.text} />) }
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}