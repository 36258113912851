import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function WeeklyChart(props) {
  const { arrival, d1_date, d2_date, start_date, final_slopes } = props;

  const lines = [];

  if (arrival && arrival.actual_processed && arrival.actual_processed.length > 0) {
    const arrivalActual = arrival.actual_processed.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', keyOrder: 1, legend: true });
  }

  if (final_slopes) {
    let legend = true;
    let id = 'Arrival (Predicted)';
    for (let slope of final_slopes) {
      let lineData = [
        {
          date: new Date(slope.start_date),
          value: slope.slope,
        },
        {
          date: new Date(slope.end_date),
          value: slope.slope,
        }
      ];

      lines.push({ id: id, data: lineData, color: arrivalLineColor, strokeStyle: 'dashed', keyOrder: 3, legend: legend });
      legend = false;
      id += '+';
    }
  }



  // if (arrival && arrival.predicted && arrival.predicted.length > 0) {
  //   const arrivalPredicted = arrival.predicted.map(obj => formatDatapoint(obj));
  //   lines.push({ id: 'Arrival (Predicted)', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', keyOrder: 3, legend: true });
  // }

  return <NivoChart 
    title='Weekly Defect Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='24vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
