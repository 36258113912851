import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor, closureLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function CumulativeChart(props) {
  const { arrival, closure, d1_date, d2_date, start_date } = props;

  const lines = [];

  function dataExists(data) {
    return data.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0) !== 0
  }

  if (arrival) {
    if (arrival.actual_processed && arrival.actual_processed.length > 0) {
      const arrivalActual = cumulateDatapoints(arrival.actual_processed).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Arrival (Actual)', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', legend: true });
    }
    else if (arrival.actual && arrival.actual.length > 0) {
      const arrivalActual = cumulateDatapoints(arrival.actual).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Arrival (Actual)', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', legend: true });
    }
  }
  
  if (closure) {
    if (closure.actual_processed && closure.actual_processed.length > 0) {
      if (dataExists(closure.actual_processed)) {
        const closureActual = cumulateDatapoints(closure.actual_processed).map(obj => formatDatapoint(obj));
        lines.push({ id: 'Closure (Actual)', data: closureActual, color: closureLineColor, strokeStyle: 'solid', legend: true });
      }
    }
    else if (closure.actual && closure.actual.length > 0 && dataExists(closure.actual)) {
      const closureActual = cumulateDatapoints(closure.actual).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Closure (Actual)', data: closureActual, color: closureLineColor, strokeStyle: 'solid', legend: true });
    }
  }

  if (arrival && arrival.predicted && arrival.predicted.length > 0) {
    const arrivalPredicted = cumulateDatapoints(arrival.predicted).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Predicted)', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', legend: true });
  }

  if (closure && closure.predicted && closure.predicted.length > 0 && dataExists(closure.predicted)) {
    const closurePredicted = cumulateDatapoints(closure.predicted).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure (Predicted)', data: closurePredicted, color: closureLineColor, strokeStyle: 'dashed', legend: true });
  }

  return <NivoChart 
    title='Cumulative Defect Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='24vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
