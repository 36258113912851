import React from 'react';
import DeliveryTable from './DeliveryTable';
import { formatNumber } from '../../../common/data_display/utils';

export default function FixTable(props) {
  const { metrics } = props;

  const tableProps = {
    title: 'Will we fix enough defects?',
    redThreshold: 10,
    yellowThreshold: 5,
    rows: [
      { 
        name: '90% Lower/Upper Limit', 
        D1: (metrics.lower_limit_d1 && metrics.upper_limit_d1) ? `${metrics.lower_limit_d1} / ${metrics.upper_limit_d1}` : 'NA', 
        D2: (metrics.lower_limit_d2 && metrics.upper_limit_d2) ? `${metrics.lower_limit_d2} / ${metrics.upper_limit_d2}` : 'NA'
      },
      { 
        name: 'Open Defects', 
        D1: formatNumber(metrics.open_defects_at_d1), 
        D2: formatNumber(metrics.open_defects_at_d2)},
      { 
        name: 'Percentage Open', 
        D1: `${formatNumber(metrics.pct_open_at_d1)}%`,
        D2: `${formatNumber(metrics.pct_open_at_d2)}%`,
      },
    ],
  };

  return <DeliveryTable {...tableProps} />
}
