import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../common/data_display/utils';
import NivoChart from '../../../common/data_display/NivoChart';

export default function ByItemChart(props) {
  const { items, cumulative, dataSelector, title, d1_date, d2_date, start_date } = props;

  const lines = [];
  const colors = ['yellow', 'orange', 'purple', 'green', 'blue', 'red'];

  items.forEach(x => {
    const rawData = dataSelector(x);
    const color = colors.pop();

    const reportData = cumulative ? cumulateDatapoints(rawData) : rawData;

    const startDate = parseDate(start_date);
    const formattedData = reportData.map(obj => formatDatapoint(obj)).filter(obj => new Date(obj.date) >= startDate);
    lines.push({ id: x.name, data: formattedData, color: color, strokeStyle: 'solid', legend: true });
  });

  // HiddenData is used to ensure the x-axis time series expands all the way out past D2
  const hiddenData = items[0]?.report.arrival.predicted.map(x => formatDatapoint(
    {
      ...x,
      value: 0,
    }));
  lines.push({ id: "hidden", data: hiddenData, strokeStyle: 'hidden' });

  return <NivoChart 
    title={title} 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='20vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
