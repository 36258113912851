import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { secondaryMain } from '../../constants/colors';
import InfoButton from '../data_display/InfoButton';
import InfoModal from '../data_display/InfoModal';

const useStyles = makeStyles({
  root: {
    width: '100%',
    "&:not(:last-child)": {
      marginBottom: 12,
    },
  },
  content: {
    paddingTop: 12,
  },
  title: {
    display: 'inline-block',
    paddingBottom: 2,
    color: secondaryMain,
  },
  titleExtra: {
    'float': 'right',
  },
  infoButton: {
    display: 'inline-block',
    marginTop: -8,
  },
});

export default function SakuraCard(props) {
  const classes = useStyles();
  const { title, modalProps, titleExtra, children } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const modalPropsFinal = modalProps ? { ...modalProps, open: modalOpen, onClose: handleModalClose } : undefined;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} gutterBottom variant="h3">
          {title}
        </Typography>
        {modalPropsFinal ? 
          <div className={classes.infoButton}>
            <InfoButton onClick={handleModalOpen} />
          </div>
          :
          <></>
        }

        <div className={classes.titleExtra}>
          {titleExtra}
        </div>

        <Divider />
        <br/>

        {children}

        {modalPropsFinal ? <InfoModal {...modalPropsFinal} /> : <></>}
      </CardContent>
    </Card>
  )
}
