import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { SubmitButton } from '../../forms/buttons';
import { secondaryMain } from '../../../constants/colors';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  infoButton: {
    minWidth: 30,
    marginLeft: 25,
  },
  modalBody: {
    position: 'absolute',
    top: 'calc(50% - 100px)',
    left: 'calc(50% - 200px)',
    width: 400,
    height: 200,
    outline: 'none',
    border: 'none',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
  form: {
    height: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  submitButton: {
    width: '100%',
  },
  modalTitle: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

function EditModal(props) {
  const classes = useStyles();
  const { baseApiUri, uri, jwtToken, name, afterSave, entityName } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [editName, setEditName] = React.useState(name);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const openModal = (e) => {
    setModalOpen(true);
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setModalOpen(false);
    setEditName(name);
    e.stopPropagation();
    e.preventDefault();
  };

  const onKeyDown = (e) => {
    if (e.key !== "Escape") {
      e.stopPropagation();
    }
  };

  const onChange = (e) => {
    setEditName(e.target.value);
  };

  const onSave = () => {
    const headers = { Authorization: jwtToken };
    setErrorMessage(undefined);

    const body = {
      name: editName,
    };

    axios.put(`${baseApiUri}${uri}`, body, {headers})
      .then(response => {
        setModalOpen(false);
        afterSave && afterSave();
      })
      .catch(error => {
        console.log(error);
        setErrorMessage(`There was an error editing ${entityName.toLowerCase()}.`);
      })
  };

  return (
    <>
      <Button className={classes.infoButton} onClick={openModal} >
        <EditIcon fontSize='small' />
      </Button>

      <Modal
        open={modalOpen}
        onClose={handleClose}
      > 
        <div className={classes.modalBody} onClick={(e) => e.stopPropagation()}>
          <form className={classes.form} onSubmit={ (e) => { onSave(); e.preventDefault(); } }>
            <Typography className={classes.modalTitle} gutterBottom variant='h3'>
              Edit {entityName} Name
            </Typography>
            <Divider />

            <TextField
              autoFocus
              className={classes.input}
              label={`${entityName} Name`}
              value={editName}
              variant='outlined'
              onChange={onChange}
              onKeyDown={onKeyDown}
              required
            />

            <SubmitButton className={classes.submitButton} text='Save' />

            <Typography className={classes.error}>{errorMessage}</Typography>
          </form>
        </div>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(EditModal);