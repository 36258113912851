import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Papa from 'papaparse';
import { parse } from 'date-fns';

import LatestUpload from './LatestUpload';
import SakuraCard from '../../../common/layout/SakuraCard';
import { primaryLightest, secondaryMain } from '../../../constants/colors';
import { formatDate, formatDatapoint, parseDate, cumulateDatapoints } from '../../../common/data_display/utils';
import DefectChart from '../../../common/data_display/DefectChart';
import NivoChart from '../../../common/data_display/NivoChart';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    marginBottom: 16,
    marginTop: 0,
  },
  label: {
    fontWeight: 'bold',
    paddingLeft: 14,
  },
  selectButton: {
    width: 150,
    fontWeight: 'bold',
    // background: 'silver',
  },
  submitButton: {
    width: 280,
  },
  fileName: {
    display: 'inline-block',
    borderRadius: 4,
    marginLeft: 12,
    width: 'calc(100% - 162px)',
    border: '1px solid silver',
    background: primaryLightest,
    // width: 'calc(100% - 220px)',
    padding: '6px 8px',
  },
  templateContainer: {
    marginTop: '10px',
  },
  templateLink: {
    marginLeft: '20px',
  },
  heading: {
    paddingBottom: 2,
    color: secondaryMain,
  },
}));

function DefectUploader(props) {
  const classes = useStyles();
  const { isAdmin, company, release, jwtToken, baseApiUri } = props;

  const [file, setFile] = React.useState();
  const [existingData, setExistingData] = React.useState();
  const [newData, setNewData] = React.useState();
  const [error, setError] = React.useState(false);
  const [previewError, setPreviewError] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const disabled = (company && company.id === 1) && !isAdmin;

  const setArrivalAndClosure = (defectList, setData) => {
    let arrival = {};
    let closure = {};

    let addTo = (obj, key) => {
      if (obj[key]) {
        obj[key]++;
      } else {
        obj[key] = 1;
      }
    };

    for (let defect of defectList) {
      addTo(arrival, formatDate(defect.arrival_date));
      if (defect.closure_date) {
        addTo(closure, formatDate(defect.closure_date));
      }
    }

    let buildAndCumulate = (obj) => {
      var combined = [];
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          combined.push({
            date: new Date(prop),
            value: obj[prop]
          });
        }
      }
      combined = combined.sort((a, b) => a.date - b.date);

      let cumulated = cumulateDatapoints(combined);
      return cumulated;
    };

    setData({
      arrival: buildAndCumulate(arrival),
      closure: buildAndCumulate(closure)
    });
  };

  React.useEffect(() => {
  }, [release]);

  React.useEffect(() => {
    if (release) {
      setNewData(undefined);
      setExistingData(undefined);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };
      const getUrl = (r) => `/releases/${r.id}/defects`;


      return axios.get(`${baseApiUri}${getUrl(release)}`, { params: {}, headers })
        .then(response => {
          setArrivalAndClosure(response.data?.defects, setExistingData);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, jwtToken, baseApiUri]);

  React.useEffect(() => {
    if (!file) {
      setNewData(null);
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        console.log(results);

        if (results.errors && results.errors.length > 0) {
          setPreviewError('Error parsing file');
          setNewData(null);
          return;
        }

        let defectList = [];
        for (let row of results.data) {
          let defect = {
            defect_id: row.defect_id,
            component: row.component,
            severity: row.severity,
            arrival_date: parse(row.arrival_date, 'yyyy-MM-dd', new Date()),
            closure_date: row.closure_date ? parse(row.closure_date, 'yyyy-MM-dd', new Date()) : row.closure_date,
          };

          defectList.push(defect);
        }

        setArrivalAndClosure(defectList, setNewData);
      }
    });

  }, [file]);

  const handleSubmit = () => {
    if (release && file && file.name) {
      setError(false);
      setFormSubmitting(true);

      const url = `${baseApiUri}/releases/${release.id}/defects`;
      const headers = { Authorization: jwtToken };

      const formData = new FormData();
      formData.append("file", file, file.name);

      return axios.post(url, formData, {headers})
        .then(response => {
          setFormSubmitting(false);

        })
        .catch(error => {
          console.log(error);
          setError(true);
          setFormSubmitting(false);
        })
      }
    };

  const selectButtonProps = {
    color: "secondary",
    variant: "outlined",
    disabled: disabled || formSubmitting,
    className: classes.selectButton,
    component: "label"
  };

  const submitButtonProps = {
    color: "secondary",
    variant: "contained",
    type: "submit",
    disabled: formSubmitting || !file || !file.name,
    className: classes.submitButton,
  };

  const lines = [];

  if (existingData) {
    lines.push({ id: 'Arrival (Existing)', data: existingData.arrival.map(obj => formatDatapoint(obj)), color: 'red', strokeStyle: 'solid', legend: true });
    lines.push({ id: 'Closure (Existing)', data: existingData.closure.map(obj => formatDatapoint(obj)), color: 'green', strokeStyle: 'solid', legend: true });
  }
  if (newData) {
    lines.push({ id: 'Arrival (New)', data: newData.arrival, color: 'orange', strokeStyle: 'dashed', legend: true });
    lines.push({ id: 'Closure (New)', data: newData.closure, color: 'blue', strokeStyle: 'dashed', legend: true });
  }

  return (
      <SakuraCard title='Upload Defect Data' titleExtra={<a href='/assets/Template_Defect_Input_Data.csv' download>Download Sample Defects</a>}>
        <form onSubmit={ (e) => { handleSubmit(); e.preventDefault(); } }>
          <div>
            <Button {...selectButtonProps}>
              Choose CSV File
              <input type="file" onChange={(e) => setFile(e.target.files[0])} accept=".csv" hidden />
            </Button>
            <label className={classes.fileName}>{ file?.name ?? 'No file chosen' }</label>
          </div>

          <br/>

          <Box align='center' style={{flex: '0 0 100%'}}>
            <Button {...submitButtonProps}>
              {`Upload Defects`}
            </Button>
          </Box>
        </form>

        <br/>
        <br/>
        <Divider/>
        <br/>

        <LatestUpload error={error} />

        <br/>

        <NivoChart 
          title='Defects Arrival/Closure' 
          lines={lines}
          chartHeight='15vw'
          allowHidingLines={true}
          allowHoverTooltip={true}
        />

      </SakuraCard>
  )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.common.isAdmin,
    company: state.common.company,
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(DefectUploader)


