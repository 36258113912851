import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import SakuraCard from '../../../common/layout/SakuraCard';
import { primaryLightest, secondaryMain } from '../../../constants/colors';
import { black } from 'material-ui/styles/colors';


const useStyles = makeStyles((theme) => ({
  list: {
    listStylePosition: 'inside',
    padding: 0,
    margin: 0
  },
  csvLink: {
    color: 'blue',
    textDecoration: 'none'
  },
  subheadingTitle: {
    display: 'inline-block',
    paddingBottom: 2,
    marginTop: 16,
    color: secondaryMain,
  },
  bodyText: {
    color: black,
  },
}));

function Notes() {
  const classes = useStyles();
  return (
    <SakuraCard title='Need help uploading your data to STAR?'>
      <Typography className={classes.bodyText} variant="body">
        We can help you get started! 
        Take a look at our <a href={`${process.env.REACT_APP_LANDING_URL}/video-library/upload-data-to-star`}>Data Upload Video</a> which guides you through the process. 
        If you still have questions or run into any issues, don't hesitate to reach out to us through our <a href={process.env.REACT_APP_CONTACT_US_URL}>Contact Us</a> page. 
        <br/>
      </Typography>
      
      <Typography className={classes.subheadingTitle} gutterBottom variant="h3">
        Notes
      </Typography>
      <Divider />
      <br/>
      <ol className={classes.list}>
        <li>
          The date format should be in <b>yyyy-mm-dd</b>
        </li>
        <li>
          The column headers should be the same as the sample data, e.g. 
          <b> defect_id, component, severity, arrival_date, closure_date</b>
        </li>
        <li>
          Open the CSV file with a text editor (e.g. WordPad) to see the original date format
        </li>
      </ol>
    </SakuraCard>
  )
}

export default Notes;


