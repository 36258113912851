import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { primaryLightest, secondaryMain, grey } from '../../constants/colors';

const useStyles = makeStyles({
  content: {
    paddingTop: 12,
  },
  title: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  table: {
    border: `1px solid ${grey}`,
  },
  cell: {
    paddingLeft: 12,
    paddingRight: 12,
    borderColor: grey,
    background: primaryLightest,
  },
  rowName: {
    fontSize: 12,
    lineHeight: '12px',
    fontStyle: 'italic',
  },
  rowValue: {
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 'bold',
  },
});


export default function DataTable(props) {
  const classes = useStyles();
  const { title, rows } = props;

  return (
    <Card>
      <CardContent className={classes.content}>
        <Typography className={classes.title} gutterBottom variant="h3">
          {title}
        </Typography>

        <Table size="small" aria-label="table" className={classes.table}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" className={classes.cell}>
                  <Typography className={classes.rowName}>{row.name}</Typography>
                </TableCell>
                <TableCell align="right" className={classes.cell}>
                  <Typography className={classes.rowValue}>{row.value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </CardContent>
    </Card>
  );
}