import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

export default function RouteNotFound(props) {

  return (
    <div style={{ borderRadius: 8, color: 'grey', width: 400, padding: 24, margin: '0 auto', marginTop: '24px', textAlign: 'center' }}>
      <ReportProblemIcon style={{ fontSize: 190 }}/>

      <Typography style={{ fontSize: 24 }}>
        <b>404:</b> View Does Not Exist
      </Typography>

      <br/>
   </div>
  )
}
