import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NO_DATA, UPLOADING, PROCESSING, ERROR, FINISHED } from './UploadStatuses';
import { NoDataStatus, UploadingStatus, ProcessingStatus, ErrorStatus, FinishedStatus } from './UploadStatuses';
import { useInterval } from '../../../common/hooks/UseInterval';

const axios = require('axios').default;

function LatestUpload(props) {
  const { release, jwtToken, baseApiUri, error } = props;

  const [latestUpload, setLatestUpload] = useState();
  const [status, setStatus] = useState();

  const loadLatest = () => {
    if (!release || !jwtToken) {
      return;
    }

    const url = `${baseApiUri}/releases/${release.id}/effort/latest`;
    const headers = { Authorization: jwtToken };

    return axios.get(url, {headers})
      .then(response => {
        setLatestUpload(response.data);
        setStatus(response.data.status.toUpperCase());
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setStatus(NO_DATA);
        }
        else {
          setStatus(ERROR);
        }
      })
  };

  // This will take care of calling loadLatest every 3 seconds to auto refresh
  useInterval(loadLatest, 3000);

  // This will call loadLatest immediately on page load or when the release is changed
  useEffect(() => {
    setStatus(undefined);
    loadLatest();
  }, [release, jwtToken]);

  const fileName = latestUpload && latestUpload.file_name ? latestUpload.file_name : 'your file';

  return (
    <div>
      {!error && status === NO_DATA && <NoDataStatus dataType='effort' />}
      {!error && status === UPLOADING && <UploadingStatus fileName={fileName} />}
      {!error && status === PROCESSING && <ProcessingStatus fileName={fileName} />}
      {!error && status === FINISHED && <FinishedStatus fileName={fileName} uuid={latestUpload.uuid} rowCount={latestUpload.row_count} uploadedAt={latestUpload.created_at} />}
      {!error && status === ERROR && <ErrorStatus fileName={fileName} />}
      {error && <ErrorStatus fileName={fileName} />}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(LatestUpload)


