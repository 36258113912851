import React, { useMemo } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTableStyles } from '../../../common/styles/TableStyles';
import { cumulateDatapoints, formatDate, formatNumber } from '../../../common/data_display/utils';

function CustomCell(props) {
  return (
    <TableCell className={props.classes} align="center" colSpan={props.colSpan} rowSpan={props.rowSpan}>
      {props.children}
    </TableCell>
  );
}

export default function DefectTable(props) {
  const classes = useTableStyles();
  const { metrics, arrival, closure } = props;

  const currentDate = arrival?.actual?.length 
    ? arrival.actual[arrival.actual.length - 1].date 
    : 'NA';

  const rows = useMemo(() => {
    const cumulativeClosure = cumulateDatapoints(closure.actual);
    const currentClosed = cumulativeClosure.length 
      ? cumulativeClosure[cumulativeClosure.length - 1].value
      : 0;

    const hasActual = arrival?.actual?.length > 0;
    const currentDefault = hasActual ? 0 : 'NA';

    return [
      {
        defectType: 'Arrival',
        current: hasActual ? metrics.current_defects : currentDefault,
        d1: metrics.defects_at_d1,
        d2: metrics.defects_at_d2,
        total: metrics.total_defects,
      },
      {
        defectType: 'Closure',
        current: hasActual ? currentClosed : currentDefault,
        d1: metrics.closed_at_d1,
        d2: metrics.closed_at_d2,
        total: metrics.predicted_closure,
      },
      {
        defectType: 'Open',
        current: hasActual ? metrics.current_defects - currentClosed : currentDefault,
        d1: metrics.open_defects_at_d1,
        d2: metrics.open_defects_at_d2,
        total: metrics.total_defects ? metrics.total_defects - metrics.predicted_closure : undefined,
      },
    ];
  }, [metrics, arrival, closure]);

  const headerClasses = clsx(classes.headCell, classes.head);
  const topHeaderClasses = clsx(classes.headCell, classes.head, classes.noBorder);
  const nameClasses = clsx(classes.borderedCell, classes.cell, classes.rowName);
  const valueClasses = clsx(classes.borderedCell, classes.valueCell, classes.rowValue);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom variant="h3">
            Defect Arrival / Closure
          </Typography>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomCell classes={headerClasses}>
                </CustomCell>
                <CustomCell classes={headerClasses}>
                  Latest <i>({formatDate(currentDate)})</i>
                </CustomCell>
                <CustomCell classes={headerClasses}>
                  D1 <i>({formatDate(props.d1_date)})</i>
                </CustomCell>
                <CustomCell classes={headerClasses}>
                  D2 <i>({formatDate(props.d2_date)})</i>
                </CustomCell>
                <CustomCell classes={headerClasses}>
                  Total
                </CustomCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow>
                    <CustomCell classes={nameClasses}>
                      {row.defectType}
                    </CustomCell>
                    <CustomCell classes={valueClasses}>
                      {formatNumber(row.current)}
                    </CustomCell>
                    <CustomCell classes={valueClasses}>
                      {formatNumber(row.d1)}
                    </CustomCell>
                    <CustomCell classes={valueClasses}>
                      {formatNumber(row.d2)}
                    </CustomCell>
                    <CustomCell classes={valueClasses}>
                      {formatNumber(row.total)}
                    </CustomCell>
                  </TableRow>
              )})}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}