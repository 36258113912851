import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export const NO_DATA = 'NO_DATA';
export const UPLOADING = 'UPLOADING';
export const PROCESSING = 'PROCESSING';
export const ERROR = 'ERROR';
export const FINISHED = 'FINISHED';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    color: 'lightblue',
  }
}));

export const NoDataStatus = (props) => (
  <Alert severity="warning">
    <AlertTitle>No Data Uploaded</AlertTitle>
    It looks like you haven't uploaded any {props.dataType} data yet. Use the uploader above to get your first dataset uploaded! 
    If you require any assistance you can <a href={process.env.REACT_APP_CONTACT_US_URL}>Contact Us</a> at any time.
  </Alert>
)

export const UploadingStatus = ({fileName}) => {
  const classes = useStyles();

  return (
    <Alert severity="info">
      <AlertTitle>Uploading...</AlertTitle>
      <b>{fileName}</b> is currently being uploaded.
      <Box p={4} align='center'>
        <CircularProgress className={classes.circularProgress}/>
      </Box>
    </Alert>
  )
}

export const ProcessingStatus = ({fileName}) => {
  const classes = useStyles();

  return (
    <Alert severity="info">
      <AlertTitle>Processing...</AlertTitle>
      <b>{fileName}</b> has been uploaded and is now being processed.
      <Box p={4} align='center'>
        <CircularProgress className={classes.circularProgress}/>
      </Box>
    </Alert>
  )
}

export const ErrorStatus = ({fileName}) => (
  <Alert severity="error">
    <AlertTitle>Upload Unsuccessful</AlertTitle>
    We were unable to process <b>{fileName}</b>. Please check that this file is correctly formatted. If this problem persists, please <a href={process.env.REACT_APP_CONTACT_US_URL}>get in touch</a>.
  </Alert>
)

export const FinishedStatus = (props) => {
  const {uuid, fileName, rowCount, uploadedAt} = props;

  return (
    <Alert severity="success">
      <AlertTitle>Uploaded Successfully @ <i>{uploadedAt}</i></AlertTitle>
      <b>{fileName}</b> has been uploaded and <b>{rowCount} items</b> have been processed. <i>({uuid})</i>
    </Alert>
  )
}