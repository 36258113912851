import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {secondaryMain, darkGrey} from '../../../constants/colors';
import EditModal from './EditModal';
import AddModal from './AddModal';


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 140,
    padding: '0px 12px',
    background: 'white',
    borderLeft: `1px solid ${darkGrey}`,
    borderRight: `1px solid ${darkGrey}`,
  },
  menuItem: {
    justifyContent: 'space-between',
  },
  selectRoot: {
    padding: 0,
    marginTop: -4,
    fontSize: 12,
    '&.MuiSelect-root button': {
      display: 'none',
    }
  },
  selectLabel: {
    padding: '0px 16px',
    fontWeight: 'bold',
    color: secondaryMain,
    fontSize: 14,
    "&.Mui-focused": {
      color: secondaryMain
    },
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  addItem: {
    padding: 0,
  }
}));


export default function DropdownSelect(props) {
  const { label, value, items, onChange, entityName, onUpdate, addUri, getEditUri, isAuthenticated } = props;
  const classes = useStyles();

  const formControlProps = {
    className: classes.formControl,
  };

  const inputLabelProps = {
    id: `${label}-select`,
    shrink: true,
    className: classes.selectLabel,
  };

  const [open, setOpen] = React.useState(false);

  const selectProps = {
    labelId: `${label}-select-label`,
    value: value,
    disableUnderline: true,
    onChange: onChange,
    classes: {
      root: classes.selectRoot,
    },
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
  };

  return (
    <FormControl {...formControlProps}>
      <InputLabel {...inputLabelProps}>
        {label}
      </InputLabel>
      <Select {...selectProps}>
        {
          items.map((item) => (
            <MenuItem className={classes.menuItem} value={item}>
              {item.name}
              { isAuthenticated && <EditModal entityName={entityName} name={item.name} uri={getEditUri(item.id)} afterSave={onUpdate} /> } 
            </MenuItem>
          )
        )}
        { isAuthenticated && 
          <MenuItem className={classes.addItem}>
            <AddModal entityName={entityName} uri={addUri} afterSave={onUpdate} />
          </MenuItem> 
        }
      </Select>
    </FormControl>
  );
}
