import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import ByItemChart from './ByItemChart';
import ItemsTable from './ItemsTable';

const axios = require('axios').default;

function ByItemView(props) {
  const { release, jwtToken, baseApiUri, getUrl, commonState, byItemConfig } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [itemReports, setItemReports] = React.useState([]);
  const [reportError, setReportError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);


  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setItemReports([]);
      setReportError(false);
      setReportNotFound(false);
    
      const itemList = byItemConfig.commonStateItemSelector(commonState);
      const items = itemList.map(x => {
        return {
          ...x,
        }
      }).sort((a, b) => a.name.localeCompare(b.name));

      const headers = { Authorization: jwtToken };

      items.forEach(x => {
        const params = {};
        params[byItemConfig.getUrlParamName] = x.id;

        x.promise = axios.get(`${baseApiUri}${getUrl(release)}`, { params, headers })
          .then(response => {
            x.report = response.data;
          })
          .catch(err => {
            if (err.response.status === 404) {
              setReportNotFound(true);
            }
            else if (err.response.status >= 500) {
              setReportError(true);
            }
          });
      });

      Promise.allSettled(items.map(x => x.promise)).
        then((results) => {
          
          setItemReports(items)
        });
    }
  }, [release, getUrl, jwtToken, baseApiUri]);

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError />
  if (reportNotFound) return <ReportNotFound />

  const arrivalProps = {
    title: 'Cumulative Arrival',
    cumulative: true,
    items: itemReports,
    dataSelector: x => x.report?.arrival?.actual, 
    d1_date: itemReports[0]?.report.d1_date,
    d2_date: itemReports[0]?.report.d2_date,
    start_date: itemReports[0]?.report.start_date
  };

  const backlogProps = {
    title: 'Weekly Open',
    cumulative: false,
    items: itemReports,
    dataSelector: x => x.report?.backlog?.actual, 
    d1_date: itemReports[0]?.report.d1_date,
    d2_date: itemReports[0]?.report.d2_date,
    start_date: itemReports[0]?.report.start_date
  };

  const closureProps = {
    title: 'Weekly Arrival',
    cumulative: false,
    items: itemReports,
    dataSelector: x => x.report?.arrival?.actual, 
    d1_date: itemReports[0]?.report.d1_date,
    d2_date: itemReports[0]?.report.d2_date,
    start_date: itemReports[0]?.report.start_date
  };

  const title = `Defects By ${byItemConfig.itemName} View`;

  return (
    <React.Fragment>
      <ViewTitle title={title}/>
      {
        itemReports[0] && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12} md={6}>
              <ByItemChart {...arrivalProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ByItemChart {...backlogProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ByItemChart {...closureProps} />
            </Grid>
            <Grid item xs={9} md={6}>
              <ItemsTable items={itemReports} title={byItemConfig.itemNamePlural} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    commonState: state.common,
  };
} 

export default connect(mapStateToProps)(ByItemView)
