import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {XYPlot, Crosshair, LineSeries, MarkSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, makeVisFlexible, DiscreteColorLegend, Borders} from 'react-vis';
import { primaryLight, primaryLightest, secondaryMain, grey } from '../../constants/colors';
import { getMinDataDate, getMinDate, getMaxDataDate, getMaxDate } from './utils';

const moment = require('moment');

const FlexibleXYPlot = makeVisFlexible(XYPlot); 

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 12,
  },
  chartAspect: {
    width: '100%',
  },
  title: {
    paddingBottom: 2,
    color: secondaryMain,
  },
  legend: {
    borderRadius: 2,
    fontSize: 10,
    fontWeight: 'bold',
    border: `1px solid ${grey}`,
    background: primaryLightest,
    margin: '0 auto',
    marginTop: 12,
    width: 'fit-content',
  },
  dLabel: {
    fontSize: 8,
    color: 'black',
    border: '1px solid black',
    background: primaryLight,
    fontWeight: 'bold',
    padding: '2px 4px',
    marginTop: -17,
    width: '100%',
    borderRadius: 4,
  },
  delayedLabel: {
    marginTop: 50,
  },
}));


export default function EffortChart(props) {
  const classes = useStyles();
  const { title, lines, scatters, chartHeight, d1_date, d2_date, previous_D1, previous_D2, start_date, end_date, yAxis2, yAxis2Titles, tickFormat, 
    xTitle, hideKey } = props;

  let lastTick = undefined;

  const safeLines = lines ?? [];
  const safeScatters = scatters ?? [];

  const tickFormatMethod = tickFormat ? tickFormat : (date) => {
    const monthIndex = date.getMonth();

    if (lastTick === monthIndex) {
      return;
    }

    lastTick = monthIndex;

    if (monthIndex === 0) {
      return date.getFullYear();
    }

    return moment.monthsShort()[monthIndex];
  };

  const minDataDate = getMinDataDate(lines, d1_date);
  const maxDataDate = getMaxDataDate(lines, d2_date);
  const xMin = start_date ? getMinDate([start_date, minDataDate]) : minDataDate;
  const xMax = end_date ? getMinDate([end_date, maxDataDate]) : maxDataDate;

  const plotProps = {
    xType: 'time',
    margin: yAxis2 ? { left: 48, right:48, bottom: 24, top: 24 } : { left: 48, bottom: 24, top: 24 },
    getX: d => d.date,
    getY: d => d.value,
    xDomain: [xMin, xMax],
  };

  const discreteColorLegendProps = {
    className: classes.legend,
    orientation: 'horizontal',
    items: safeLines.concat(safeScatters).filter(x => x.title),
  };

  const crosshairProps = {
    color: 'black',
    style: {
      line: {
        background: 'black',
        width: 1,
      },
    },
  }

  const propsD1 = {
    values: [{date: d1_date, value: 0}],
  };

  const propsD2 = {
    values: [{date: d2_date, value: 0}],
  };

  const prevCrosshairProps = {
    color: 'orange',
    style: {
      line: {
        background: 'orange',
        width: 2,
      },
    },
  }

  const propsPrevD1 = {
    values: [{date: previous_D1, value: 0}],
  };

  const propsPrevD2 = {
    values: [{date: previous_D2, value: 0}],
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <Typography className={classes.title} gutterBottom variant="h3">
          {title}
        </Typography>
        <Divider/>
        <div className={classes.chartAspect} style={{ height: chartHeight }}>
          <FlexibleXYPlot {...plotProps}>
            <VerticalGridLines />
            <HorizontalGridLines />

            { d1_date && (
              <Crosshair {...crosshairProps} {...propsD1}>
                <div className={classes.dLabel}>
                  D1
                </div>
              </Crosshair>
            )}

            { d2_date && (
              <Crosshair {...crosshairProps} {...propsD2}>
                <div className={classes.dLabel}>
                  D2
                </div>
              </Crosshair>
            )}

            { previous_D1 && (
              <Crosshair {...prevCrosshairProps} {...propsPrevD1}>
                <div className={clsx(classes.dLabel, classes.delayedLabel)}>
                  D1
                </div>
              </Crosshair> 
            )}

            { previous_D2 && (
              <Crosshair {...prevCrosshairProps} {...propsPrevD2}>
                <div className={clsx(classes.dLabel, classes.delayedLabel)}>
                  D2
                </div>
              </Crosshair> 
            )}

            {safeLines?.map((item) => <LineSeries {...item} />)}
            {safeScatters?.map((item) => <MarkSeries {...item} />)}
            <Borders style={{all: {fill: '#fff'}}} /> 
            <XAxis tickFormat={tickFormatMethod} title={xTitle}/>
            {(yAxis2 && safeLines[1]) ? (<YAxis orientation="right" yDomain={safeLines[1]["yDomain"]} title={yAxis2Titles ? yAxis2Titles[1] : ""} />) : <p />}
            {(yAxis2 && safeLines[0]) ? (<YAxis orientation="left" yDomain={safeLines[0]["yDomain"]} title={yAxis2Titles ? yAxis2Titles[0] : ""} />) : <YAxis />} 
          </FlexibleXYPlot>
        </div>
        { !hideKey && <DiscreteColorLegend {...discreteColorLegendProps} /> }
      </CardContent>
    </Card>
  )
}
