import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import InfoButton from '../../../common/data_display/InfoButton';
import InfoModal from '../../../common/data_display/InfoModal';
import { useTableStyles } from '../../../common/styles/TableStyles';
import { secondaryMain } from '../../../constants/colors';

export default function ItemsTable(props) {
  const { items, title } = props;
  const maxItems = 10;
  const classes = useTableStyles();
  const headerClasses = clsx(classes.headCell, classes.head);
  const topHeaderClasses = clsx(classes.headCell, classes.head, classes.noBorder);

  const sumFunc = (a, b) => a + b;
  const reduce = (dataSet) => dataSet.map(x => x.value).reduce(sumFunc);
  let rows = items.map(x => {
    return {
      name: x.name,
      arrival: reduce(x.report.arrival.actual),
      backlog: x.report.backlog.actual[x.report.backlog.actual.length - 1].value,
      closure: reduce(x.report.closure.actual),
    };
  });

  if (rows.length > maxItems) {
    let topRows = rows.slice(0, maxItems - 1);
    let otherRows = rows.slice(maxItems - 1);

    const otherRow = otherRows.reduce((a, b) => {
      return {
        name: 'Others',
        arrival: a.arrival + b.arrival,
        backlog: a.backlog + b.backlog,
        closure: a.closure + b.closure,
      }
    });

    rows = [...topRows, otherRow];
  }

  const totalArrival = rows.map(x => x.arrival).reduce(sumFunc);
  const totalBacklog = rows.map(x => x.backlog).reduce(sumFunc);
  const totalClosure = rows.map(x => x.closure).reduce(sumFunc);

  const getPct = (partial, total) => Math.round(partial / total * 1000) / 10;

  rows.forEach(row => {
    row.arrivalPct = getPct(row.arrival, totalArrival);
    row.backlogPct = getPct(row.backlog, row.arrival);
    row.closurePct = getPct(row.closure, totalClosure);
  });

  const totalBacklogPct = getPct(totalBacklog, totalArrival);

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const thresholds = {
    red: 10,
    yellow: 5,
  };

  const getColorClass = (value) => {
    if (isNaN(value)) return null;

    if (value > thresholds.red ) {
      return classes.redCell;
    }
    else if (value >= thresholds.yellow ) {
      return classes.yellowCell;
    }
    else {
      return classes.greenCell;
    }
  }

  return (
    <>
      <Card>
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom variant="h3">
            Summary
          </Typography>

          <div className={classes.infoButton}>
            <InfoButton onClick={handleModalOpen} />
          </div>

          <Table size="small" aria-label="table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={headerClasses} align="center" rowSpan={2}>
                  {title}
                </TableCell>
                <TableCell className={topHeaderClasses} align="center" colSpan={2}>
                  Arrival
                </TableCell>
                <TableCell className={topHeaderClasses} align="center" colSpan={2}>
                  Open
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={headerClasses} align="center">
                  Defects
                </TableCell>
                <TableCell className={headerClasses} align="center">
                  % Defect
                </TableCell>
                <TableCell className={headerClasses} align="center">
                  Defects
                </TableCell>
                <TableCell className={headerClasses} align="center">
                  % Open
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {
                rows.map(x => (
                  <TableRow key={x.id}>
                    <TableCell className={classes.cell} align="center">
                      {x.name}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {x.arrival}
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {x.arrivalPct}%
                    </TableCell>
                    <TableCell className={classes.cell} align="center">
                      {x.backlog}
                    </TableCell>
                    <TableCell className={clsx(classes.cell, getColorClass(x.backlogPct))} align="center">
                      {x.backlogPct}%
                    </TableCell>
                  </TableRow>
                ))
              }

              <TableRow key='total'>
                <TableCell className={classes.cell} align="center">
                  Total
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {totalArrival}
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  100%
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  {totalBacklog}
                </TableCell>
                <TableCell className={clsx(classes.cell, getColorClass(totalBacklogPct))} align="center">
                  {totalBacklogPct}%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
       
      <InfoModal title="Summary Key" open={modalOpen} onClose={handleModalClose}>
        <KeyTable thresholds={thresholds} />
        <div>
          <br/>
          *3S has established quality thresholds based on previous customer experiences.
        </div>
      </InfoModal>
    </>
  );
}

const KeyTable = (props) => {
  const classes = useTableStyles();

  return (
    <Table size="small" className={clsx(classes.table, classes.thresholdTable)}>
      <TableHead>
        <TableRow className={classes.head}>
          <TableCell className={classes.headCell} align="center">
            Color
          </TableCell>
          <TableCell className={classes.headCell} align="center">
            Threshold (%)
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.redCell, classes.thresholdCellLeft)}>
            At Risk
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            {`> ${props.thresholds.red}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.yellowCell, classes.thresholdCellLeft)}>
            Warning
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            {`>= ${props.thresholds.yellow}`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.greenCell, classes.thresholdCellLeft)}>
            Acceptable
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            {`< ${props.thresholds.yellow}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
