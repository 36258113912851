import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import FilterGroup from './FilterGroup';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    fontSize: 10,
    padding: '3px 12px ',
    marginTop: -1,
    fontWeight: 'bold',
    width: 180,
  },
  filterDropdown: {
    padding: 8,
    position: 'fixed',
    height: 220,
    right: 24,
    top: 73,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));


export default function DefectFilter(props) {
  const { componentFilterGroup, severityFilterGroup, componentsOnChange, severitiesOnChange } = props;
  const [defectFilterOpen, setDefectFilterOpen] = React.useState(false);
  const classes = useStyles();
  
  const totalFilters = componentFilterGroup.selected.length + severityFilterGroup.selected.length;

  const toggleDefectFilterOpen = () => setDefectFilterOpen(!defectFilterOpen);
  const handleClickAway = () => setDefectFilterOpen(false);

  const filterButtonProps = {
    color: "secondary",
    variant: "contained",
    className: classes.filterButton,
    onClick: toggleDefectFilterOpen,
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Button {...filterButtonProps}>
          {`Defect Filters (${totalFilters})`}
        </Button>

        {defectFilterOpen &&
          <Card className={classes.filterDropdown}>
            <FilterGroup 
              {...componentFilterGroup} 
              onChange={componentsOnChange}
            />
            <FilterGroup 
              {...severityFilterGroup}
              onChange={severitiesOnChange}
            />
          </Card>
        }
      </div>
    </ClickAwayListener>
  )
}


