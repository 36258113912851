import ReleaseConfig from './release_config/main';
import AdminConfig from './admin_config/main';
import store from '../../store';
import { hideDefectFilters, showReleaseSelector } from '../../common/redux/actions';

const renderInputPage = (Component) => () => {
  store.dispatch(hideDefectFilters());
  store.dispatch(showReleaseSelector());
  return <Component />
}

const releaseConfigPath = '/config/release';
const adminConfigPath = '/config/admin'

export const configRoutes = [
  { name: 'Release Config', path: releaseConfigPath, render: renderInputPage(() => <ReleaseConfig />) },
  { name: 'Admin Config', path: adminConfigPath, adminOnly: true, render: renderInputPage(() => <AdminConfig />) },
];
