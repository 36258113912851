import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import ReleasesChart from './ReleasesChart';
import ReleasesSelector from './ReleasesSelector';

const axios = require('axios').default;

function ReleaseOverReleaseView(props) {
  const { project, jwtToken, baseApiUri, getUrl, releaseList } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [releaseReports, setReleaseReports] = React.useState([]);
  const [reportError, setReportError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (project) {
      let colors = ['black', 'gray', 'pink', 'lightseagreen', 'magenta', 'darkblue', 'cyan', 'yellow', 'orange', 'purple', 'green', 'blue', 'red'];
      colors = colors.concat(colors).concat(colors);
      setReleaseReports([]);
      setReportError(false);
      setReportNotFound(false);
    
      const releases = releaseList.map(x => {
        return {
          ...x,
          visible: true,
          color: colors.pop(),
        }
      }).sort((a, b) => a.name.localeCompare(b.name));

      const headers = { Authorization: jwtToken };

      releases.forEach(release => {
        release.promise = axios.get(`${baseApiUri}${getUrl(release)}`, { headers })
          .then(response => {
            release.report = response.data;
          })
          .catch(err => {
            if (err.response.status === 404) {
              setReportNotFound(true);
            }
            else if (err.response.status >= 500) {
              setReportError(true);
            }
          });
      });

      Promise.allSettled(releases.map(x => x.promise)).
        then((results) => {
          
          setReleaseReports(releases)
        });
    }
  }, [project, releaseList, getUrl, jwtToken, baseApiUri]);

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError />
  if (reportNotFound) return <ReportNotFound />

  return (
    <React.Fragment>
      <ViewTitle title='Release Over Release'/>
      {
        releaseReports[0] && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12}>
              <ReleasesChart releaseReports={releaseReports} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    project: state.common.project,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    releaseList: state.common.releaseList,
  };
} 

export default connect(mapStateToProps)(ReleaseOverReleaseView)
