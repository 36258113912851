import React from 'react';
import Alert from '@material-ui/lab/Alert';

export default function PredictionError(props) {
  const { error } = props;

  return (
    <>
      <Alert severity="error">
        <b>Prediction Error:</b> Prediction could not be completed successfully - <i>{error}</i>
      </Alert>
      <br/>
    </>
  )
}
