import { makeStyles } from '@material-ui/core/styles';
import { primaryLightest, secondaryMain, backgroundDark, primaryMedium, redCell, greenCell, yellowCell, grey } from '../../constants/colors';

export const useTableStyles = makeStyles({
  card: {
    height: '100%',
  },
  content: {
    paddingTop: 12,
  },
  title: {
    paddingBottom: 2,
    color: secondaryMain,
    float: 'left',
  },
  table: {
    border: `1px solid ${grey}`,
  },
  borderedCell: {
    border: `1px solid ${grey}`,
  },
  head: {
    background: primaryMedium,
  },
  headCell: {
    fontSize: 12,
    lineHeight: '12px',
    borderBottom: `1px solid ${grey}`,
    color: 'white',
    fontWeight: 'bold'
  },
  cell: {
    fontSize: 12,
    lineHeight: '12px',
    paddingLeft: 12,
    paddingRight: 12,
    borderColor: grey,
    background: primaryLightest,
  },
  nameCell: {
    borderRight: `1px solid ${grey}`
  },
  valueCell: {
    background: 'white',
  },
  rowName: {
    fontSize: 12,
    lineHeight: '12px',
    fontStyle: 'italic',
  },
  infoButton: {
    marginTop: -4,
  },
  rowValue: {
    fontSize: 12,
    lineHeight: '12px',
    fontWeight: 'bold',
  },
  redCell: {
    background: redCell,
  },
  yellowCell: {
    background: greenCell,
  },
  greenCell: {
    background: yellowCell,
  },
  thresholdTable: {
    marginTop: 12,
  },
  thresholdCellLeft: {
    borderRight: `1px solid ${backgroundDark}`,
  },
  noBorder: {
    border: 'none',
  },
});