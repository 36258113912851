import React from 'react';
import { connect } from 'react-redux';
import MpmConfig from './MpmConfig';
import PpmConfig from './PpmConfig';
import IpmConfig from './IpmConfig';
import PrmConfig from './PrmConfig';
import LpmConfig from './LpmConfig';
import PrepMpmConfig from './PrepMpmConfig';
import ViewTitle from '../../../common/layout/ViewTitle';

function AdminConfig(props) {
  return (
    <React.Fragment>
      <ViewTitle title='Admin Configuration' />
      { props.release && ( 
        <>
          <MpmConfig {...props}/>
          <br/><br/>
          <PpmConfig {...props}/>
          <br/><br/>
          <IpmConfig {...props}/>
          <br/><br/>
          <PrepMpmConfig {...props}/>
          <br/><br/>
          <PrmConfig {...props}/>
          <br/><br/>
          <LpmConfig {...props}/>
          <br/><br/>
        </>
      )}
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(AdminConfig)
