import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, daysBetween, parseDate, addDays } from '../../../common/data_display/utils';
import NivoChart from '../../../common/data_display/NivoChart';

export default function ReleasesChart(props) {
  const { releaseReports } = props;

  const [lines, setLines] = React.useState([]);
  const [d2Date, setD2Date] = React.useState(undefined);

  React.useEffect(() => {
    const result = [];
    let firstD2 = undefined;

    releaseReports.forEach(x => {
      const parsedD2 = parseDate(x.report?.d2_date);

      if (!firstD2) {
        firstD2 = parsedD2;
      }

      const daysDiff = daysBetween(firstD2, parsedD2);

      const rawData = x.report?.arrival?.predicted;

      const reportData = cumulateDatapoints(rawData);

      const formattedData = reportData.map(obj => {
        return {
          ...obj,
          date: addDays(parseDate(obj.date), daysDiff),
        };
      });
      result.push({ id: x.name, visible: x.visible, data: formattedData, color: x.color, strokeStyle: 'solid', legend: true });
    });

    setLines(result.filter(x => x.visible));
    setD2Date(firstD2);
  }, [releaseReports]);

  const tickFormat = (date) => {
    const daysDiff = daysBetween(date, d2Date);
    const weeksDiff = Math.round(daysDiff / 7); 

    return weeksDiff;
  };

  return <NivoChart 
    title='Release Over Release'
    lines={lines}
    d2_date={d2Date} 
    chartHeight='36vw'
    tickFormat={tickFormat}
    xTitle='Weeks from D2'
    hideKey={true}
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
