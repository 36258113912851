import React from 'react';
import DataTable from '../../../common/data_display/DataTable';
import { formatDate } from '../../../common/data_display/utils';


export default function DatesTable(props) {
  const { d1_date, d2_date, timestamp } = props;

  const rows = [
    { name: 'Report Date' , value: formatDate(new Date(timestamp)) },
    { name: 'D1 Date', value: formatDate(new Date(d1_date)) },
    { name: 'D2 Date', value: formatDate(new Date(d2_date)) },
  ];

  return <DataTable title='Dates' rows={rows} />
}