import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor, closureLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function CumulativeChart(props) {
  const { arrival, closure, d1_date, d2_date, start_date } = props;

  const lines = [];

  if (arrival && arrival.actual_processed && arrival.actual_processed.length > 0) {
    const arrivalPredicted = arrival.actual_processed.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Actual)', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'solid', legend: true });
  }

  if (closure && closure.actual_processed && closure.actual_processed.length > 0) {
    const closurePredicted = closure.actual_processed.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure (Actual)', data: closurePredicted, color: closureLineColor, strokeStyle: 'solid', legend: true });
  }

  if (arrival && arrival.predicted && arrival.predicted.length > 0) {
    const arrivalPredicted = arrival.predicted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Predicted)', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', legend: true });
  }

  if (closure && closure.predicted && closure.predicted.length > 0) {
    const closurePredicted = closure.predicted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure (Predicted)', data: closurePredicted, color: closureLineColor, strokeStyle: 'dashed', legend: true });
  }

  return <NivoChart 
    title='Defect Arrival / Closure Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='30vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
