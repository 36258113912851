import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';


const useStyles = makeStyles({
  filterButton: {
    fontSize: 10,
    padding: '3px 12px ',
    marginTop: -6,
    fontWeight: 'bold',
    width: 180,
    float: 'right',
  },
  icon: {
    fontSize: 18,
    paddingLeft: 4,
  }
});


export default function DownloadButton(props) {
  const classes = useStyles();
  const { onClick, disabled } = props;

  const filterButtonProps = {
    color: "secondary",
    variant: "contained",
    className: classes.filterButton,
    onClick: onClick,
    disabled: disabled,
  };

  return (
    <Button {...filterButtonProps}>
      Export Excel File <GetAppRoundedIcon className={classes.icon} />
    </Button>
  );
}