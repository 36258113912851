import React from 'react';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { secondaryMain } from '../../constants/colors';

export default function RouteNotFound(props) {

  return (
    <div style={{ borderRadius: 8, color: secondaryMain, width: 540, padding: 24, margin: '0 auto', marginTop: '24px', textAlign: 'center' }}>
      <InfoIcon style={{ fontSize: 190 }}/>

      <Typography style={{ fontSize: 24 }}>
        Report Not Found
      </Typography>

      <Typography style={{ fontSize: 14, color: 'black' }}>
        This is likely due to incomplete input data.
      </Typography>
   </div>
  )
}
