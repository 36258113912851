import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import {background, primaryLightest} from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: 4,
  },
  formGroup: {
    border: `1px solid ${background}`,
    height: 180,
    padding: 4,
    background: primaryLightest,
    width: 180,
  },
  formControlLabel: {
    fontSize: 12,
    height: 28,
    lineHeight: '28px',
  },
  checkbox: {
    transform: "scale(0.75)",
  },
}));


export default function FilterGroup(props) {
  const { label, items, selected, onChange } = props;
  const classes = useStyles();

  const createCheckbox = (item) => (
    <FormControlLabel
      className={classes.formControlLabel} 
      control={
        <Checkbox 
          className={classes.checkbox} 
          checked={selected.includes(item.id)}
          name={item.name}
          value={item.id}
          onChange={onChange}
        />
      }
      label={
        <Typography className={classes.formControlLabel}>
          {item.name}
        </Typography>
      }
    />
  );

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel className={classes.formLabel} component="legend">
        {label}
      </FormLabel>
      <FormGroup className={classes.formGroup}>
        {items.map((item) => createCheckbox(item))}
      </FormGroup>
    </FormControl>
  )
}
