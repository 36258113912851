import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../../misc/ReportError';
import BackendError from '../../../misc/BackendError';
import ReportNotFound from '../../../misc/ReportNotFound';
import ViewTitle from '../../../../common/layout/ViewTitle';
import EffortDataChart from '../EffortDataChart'
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../../common/data_display/utils';
 
const axios = require('axios').default;

function EffortDataViewIPM(props) {
  const { release, jwtToken, baseApiUri, getUrl, warnOnError } = props;
  const getEffortUrl = (r) => `/releases/${r.id}/effort`;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const [currDefectData, setCurrDefectData] = React.useState();
  const [currPreproccessedEffort, setCurrPreproccessedEffort] = React.useState();

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setReportError(false);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { headers })
        .then(response => {
          setReport(response.data);
          setReportError(Boolean(response.data.error));

          setCurrDefectData(cumulateDatapoints(response.data.arrival.actual));
          setCurrPreproccessedEffort(response.data.current_effort.combined_processed_after);

          console.log(response.data);
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response && err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, getUrl, jwtToken, baseApiUri]);
  
  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError && ((currDefectData && currPreproccessedEffort) ? !(currDefectData.length > 0 && currPreproccessedEffort.length > 0) : true)) return <ReportError error={report ? report.error : ''} warnOnError={warnOnError} />
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  const proccessedVsDefectProps = {
    title: "Defects vs. Preprocessed Effort Data - " + report?.project_name + " " + report?.release_name,
    items: {"current": currPreproccessedEffort, "previous": currDefectData},
    release_name: {"current": "Effort", "previous": "Defects"},
    d1_date: report?.d1_date,
    d2_date: report?.d2_date,
    yAxis2: true,
    yAxis2Titles: ["Effort", "Defects"],
    start_date: report?.start_date
  };

  const top_title = `Effort Data`;

  return (
    <React.Fragment>
      <ViewTitle title={top_title}/>
      {
        report && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12} md={10}>
              <EffortDataChart {...proccessedVsDefectProps} />
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(EffortDataViewIPM)