import React from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InfoButton from '../../../common/data_display/InfoButton';
import InfoModal from '../../../common/data_display/InfoModal';
import { useTableStyles } from '../../../common/styles/TableStyles';
import { secondaryMain } from '../../../constants/colors';

export const ThresholdTable = (props) => {
  const { redThreshold, yellowThreshold, tableStyles } = props;
  const classes = tableStyles;

  const redCellThreshold = `> ${redThreshold}`
  const yellowCellThreshold = `>= ${yellowThreshold}`
  const greenCellThreshold = `< ${yellowThreshold}`

  return (
    <div>
      <Table size="small" className={clsx(classes.table, classes.thresholdTable)}>
        <TableHead>
          <TableRow className={classes.head}>
            <TableCell className={classes.headCell} align="center">
              <Typography className={classes.rowValue}>
                Color
              </Typography>
            </TableCell>
            <TableCell className={classes.headCell} align="center">
              <Typography className={classes.rowValue}>
                Threshold (%)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.redCell, classes.thresholdCellLeft)}>
              <Typography className={classes.rowValue}>
                At Risk
              </Typography>
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              <Typography>
                {redCellThreshold}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.yellowCell, classes.thresholdCellLeft)}>
              <Typography className={classes.rowValue}>
                Warning
              </Typography>
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              <Typography>
                {yellowCellThreshold}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.greenCell, classes.thresholdCellLeft)}>
              <Typography className={classes.rowValue}>
                Acceptable
              </Typography>
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              <Typography>
                {greenCellThreshold}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <br/>
      <div>
        <b style={{color: secondaryMain }}>Note:</b>
        <br/> 
        <b>D1</b> = Date Ready to Release for Trial
        <br/>
        <b>D2</b> = Date Ready to Release for Deployment
        <br/><br/>
        *3S has established quality thresholds based on previous customer experiences.
      </div>
    </div>
  )
}


export default function DeliveryTable(props) {
  const classes = useTableStyles();
  const { title, rows, redThreshold, yellowThreshold } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const getColorClass = (value) => {
    if (!value) return null;

    const floatValue = parseFloat(value.replace(',',''));

    if (floatValue > redThreshold ) {
      return classes.redCell
    }
    else if (floatValue >= yellowThreshold ) {
      return classes.yellowCell
    }
    else {
      return classes.greenCell
    }
  }

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom variant="h3">
            {title}
          </Typography>
          <div className={classes.infoButton}>
            <InfoButton onClick={handleModalOpen} />
          </div>

          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow className={classes.head}>
                <TableCell className={classes.headCell} component="th" scope="row" align="center"></TableCell>
                <TableCell className={classes.headCell} align="center">
                  <Typography className={classes.rowValue}>
                    D1
                  </Typography>
                </TableCell>
                <TableCell className={classes.headCell} align="center">
                  <Typography className={classes.rowValue}>
                    D2
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
            <TableRow>
                <TableCell component="th" scope="row" align="center" className={clsx(classes.cell, classes.nameCell)}>
                  <Typography className={classes.rowName}>{rows[0].name}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
                  <Typography className={classes.rowValue}>{rows[0].D1}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
                  <Typography className={classes.rowValue}>{rows[0].D2}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center" className={clsx(classes.cell, classes.nameCell)}>
                  <Typography className={classes.rowName}>{rows[1].name}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
                  <Typography className={classes.rowValue}>{rows[1].D1}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
                  <Typography className={classes.rowValue}>{rows[1].D2}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" align="center" className={clsx(classes.cell, classes.nameCell)}>
                  <Typography className={classes.rowName}>{rows[2].name}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell, getColorClass(rows[2].D1), classes.thresholdCellLeft)}>
                  <Typography className={classes.rowValue}>{rows[2].D1}</Typography>
                </TableCell>
                <TableCell align="center" className={clsx(classes.cell, classes.valueCell, getColorClass(rows[2].D2))}>
                  <Typography className={classes.rowValue}>{rows[2].D2}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </CardContent>
      </Card>

      <InfoModal title={title} open={modalOpen} onClose={handleModalClose}>
        <ThresholdTable redThreshold={redThreshold} yellowThreshold={yellowThreshold} tableStyles={useTableStyles()} />
      </InfoModal>
    </React.Fragment>
  );
}