import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { openLineColor } from '../../../constants/colors';
import { delayDate } from './utils';
import NivoChart from '../../../common/data_display/NivoChart';

export default function CumulativeChart(props) {
  const { backlog, open_actual, delayed_delivery_output, open_predicted, d1_date, d2_date, start_date, inputValue, selectedAction } = props;

  const lines = [];

  const hasInput = inputValue && (
    inputValue?.contentValue > 0 ||
    inputValue?.developersValue > 0 ||
    inputValue?.testersValue > 0
  );

  if (backlog && backlog.actual_processed && backlog.actual_processed.length > 0) {
    const backlogActual = backlog.actual_processed.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Open (Actual)', data: backlogActual, color: openLineColor, strokeStyle: 'solid', legend: true });
  }

  if (backlog && backlog.predicted && backlog.predicted.length > 0) {
    const backlogPredicted = backlog.predicted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Open (Predicted)', data: backlogPredicted, color: openLineColor, strokeStyle: 'dashed', legend: true });
  }

  if (hasInput && open_predicted && open_predicted.length > 0) {
    const adjustedBacklog = open_predicted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Open (Adjusted)', data: adjustedBacklog, color: 'orange', strokeStyle: 'dashed', legend: true });
  }

  return <NivoChart 
    title='Open Defect Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    delayed_D1={inputValue?.delayValue > 0 && delayed_delivery_output ? new Date(delayed_delivery_output.new_d1) : undefined}
    delayed_D2={inputValue?.delayValue > 0 && delayed_delivery_output ? new Date(delayed_delivery_output.new_d2) : undefined}
    chartHeight='24vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
