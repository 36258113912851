import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {backgroundLight, primaryLight, grey, darkGrey } from '../../../constants/colors';


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 4,
    marginLeft: 72,
    paddingLeft: 12,
    width: 'calc(100% - 72px)',
    borderBottom: `1px solid ${grey}`,
    borderLeft: `4px solid ${grey}`,
    background: 'white',
    "&:hover": {
      borderLeft: `4px solid ${darkGrey}`,
      background: backgroundLight,
    },
  },
  listItemText: {
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 'bold',
  },
  selected: {
    background: primaryLight,
    borderLeft: `4px solid ${darkGrey}`,
    "&:hover": {
      background: primaryLight,
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
}));

export default function NestedMenuItem(props) {
  const { label, path } = props;
  const classes = useStyles();

  const location = useLocation();

  const listItemClasses = clsx({
    [classes.listItem]: true,
    [classes.selected]: location.pathname === path,
  });

  const listItemTextProps = {
    primary: label,
    classes: {
      primary: classes.listItemText,
    },
  };

  return (
    <NavLink to={path} className={classes.link}>
      <ListItem className={listItemClasses}>
        <ListItemText {...listItemTextProps} />
      </ListItem>
    </NavLink>
  )
}