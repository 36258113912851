import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../../misc/ReportError';
import BackendError from '../../../misc/BackendError';
import ReportNotFound from '../../../misc/ReportNotFound';
import ViewTitle from '../../../../common/layout/ViewTitle';
import EffortDataChart from '../EffortDataChart'
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../../common/data_display/utils';
 
const axios = require('axios').default;

function EffortDataViewPPM(props) {
  const { release, jwtToken, baseApiUri, getUrl, warnOnError } = props;
  const getEffortUrl = (r) => `/releases/${r.id}/effort`;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const [currDevEffort, setCurrDevEffort] = React.useState();
  const [prevDevEffort, setPrevDevEffort] = React.useState();
  const [currTestEffort, setCurrTestEffort] = React.useState();
  const [prevTestEffort, setPrevTestEffort] = React.useState();
  const [currPreproccessedEffort, setCurrPreproccessedEffort] = React.useState();
  const [prevPreproccessedEffort, setPrevPreproccessedEffort] = React.useState();

  const [prevD1Date, setPrevD1Date] = React.useState();
  const [prevD2Date, setPrevD2Date] = React.useState();

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    setPrevD1Date(undefined);
    setPrevD2Date(undefined);
  }, [release]);

  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setReportError(false);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { headers })
        .then(response => {
          setReport(response.data);
          setReportError(Boolean(response.data.error));

          setCurrDevEffort(response.data.current_effort.dev);
          setPrevDevEffort(response.data.prior_effort.dev);

          setCurrTestEffort(response.data.current_effort.test);
          setPrevTestEffort(response.data.prior_effort.test);

          setCurrPreproccessedEffort(response.data.current_effort.combined_processed_after);
          setPrevPreproccessedEffort(response.data.prior_effort.combined_processed_after);
          
          console.log(response.data);
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response && err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, getUrl, jwtToken, baseApiUri]);
  
  React.useEffect(() => {
    if (release) {
      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getEffortUrl(release)}`, { headers })
        .then(response => {
          
          if (response.data.prior_release_id) {
            return axios.get(`${baseApiUri}/releases/${response.data.prior_release_id}/reports/ipm`, { headers })
              .then(response_prev => {
                setPrevD1Date(response_prev.data.d1_date);
                setPrevD2Date(response_prev.data.d2_date);
              })
              .catch(err => {
                if (err.response && err.response.status === 404) {
                  setReportNotFound(true);
                }
                else if (err.response && err.response.status >= 500) {
                  setBackendError(true);
                }
              })
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response && err.response.status >= 500) {
            setBackendError(true);
          }
        })
    }
  }, [release, getEffortUrl, jwtToken, baseApiUri]);

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError && ((currDevEffort && currTestEffort) ? !(currDevEffort.length > 0 && currTestEffort.length > 0) : true)) return <ReportError error={report ? report.error : ''} warnOnError={warnOnError} />
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  const devEffortProps = {
    title: "Development Effort Data - " + report?.project_name + " " + (report?.prior_release_name ? report?.prior_release_name + " vs. " : " ") + report?.release_name,
    items: {"current": currDevEffort, "previous": prevDevEffort},
    release_name: {"current": report?.release_name, "previous": report?.prior_release_name},
    d1_date: report?.d1_date,
    d2_date: report?.d2_date,
    prev_d1_date: prevD1Date,
    prev_d2_date: prevD2Date,
    start_date: report?.start_date
  };

  const testEffortProps = {
    title: "Test Effort Data - " + report?.project_name + " " + (report?.prior_release_name ? report?.prior_release_name + " vs. " : " ") + report?.release_name,
    items: {"current": currTestEffort, "previous": prevTestEffort},
    release_name: {"current": report?.release_name, "previous": report?.prior_release_name},    
    d1_date: report?.d1_date,
    d2_date: report?.d2_date,
    prev_d1_date: prevD1Date,
    prev_d2_date: prevD2Date,
    start_date: report?.start_date
  };

  const preprocessedEffortProps = {
    title: "Preprocessed Effort Data - " + report?.project_name + " " + (report?.prior_release_name ? report?.prior_release_name + " vs. " : " ") + report?.release_name,
    items: {"current": currPreproccessedEffort, "previous": prevPreproccessedEffort},
    release_name: {"current": report?.release_name, "previous": report?.prior_release_name},    
    d1_date: report?.d1_date,
    d2_date: report?.d2_date,
    prev_d1_date: prevD1Date,
    prev_d2_date: prevD2Date,
    start_date: report?.start_date
  };

  const top_title = `Effort Data`;

  return (
    <React.Fragment>
      <ViewTitle title={top_title}/>
      {
        report && (
          <Grid container spacing={1} justify='center'>
            <Grid item xs={12} md={6}>
              <EffortDataChart {...devEffortProps} />
            </Grid>
            <Grid item xs={12} md={6}>
              <EffortDataChart {...testEffortProps} />
            </Grid>
            {(prevPreproccessedEffort !== undefined ? prevPreproccessedEffort.length > 0 : false) && (<Grid item xs={12} md={6}>
              <EffortDataChart {...preprocessedEffortProps} />
            </Grid>)}
          </Grid>
        )
      }
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(EffortDataViewPPM)