import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Papa from 'papaparse';
import { parse } from 'date-fns';

import LatestEffortUpload from './LatestEffortUpload';
import SakuraCard from '../../../common/layout/SakuraCard';
import { primaryLightest, secondaryMain } from '../../../constants/colors';

import { formatDate, formatDatapoint, parseDate, cumulateDatapoints } from '../../../common/data_display/utils';
import EffortChart from '../../../common/data_display/EffortChart';
import NivoChart from '../../../common/data_display/NivoChart';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    marginBottom: 16,
    marginTop: 0,
  },
  label: {
    fontWeight: 'bold',
    paddingLeft: 14,
  },
  selectButton: {
    width: 150,
    fontWeight: 'bold',
    // background: 'silver',
  },
  submitButton: {
    width: 280,
  },
  fileName: {
    display: 'inline-block',
    borderRadius: 4,
    marginLeft: 12,
    width: 'calc(100% - 162px)',
    border: '1px solid silver',
    background: primaryLightest,
    // width: 'calc(100% - 220px)',
    padding: '6px 8px',
  },
  templateContainer: {
    marginTop: '10px',
  },
  templateLink: {
    marginLeft: '20px',
  },
  heading: {
    paddingBottom: 2,
    color: secondaryMain,
  },
}));

function EffortUploader(props) {
  const classes = useStyles();
  const { isAdmin, company, release, jwtToken, baseApiUri } = props;

  const [file, setFile] = React.useState();
  const [existingEffortData, setExistingEffortData] = React.useState();
  const [newEffortData, setNewEffortData] = React.useState();
  const [effort, setEffort] = React.useState();
  const [error, setError] = React.useState(false);
  const [previewError, setPreviewError] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const [report, setReport] = React.useState();
  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const disabled = (company && company.id === 1) && !isAdmin;

  React.useEffect(() => {
    setFile(undefined);
    setNewEffortData(undefined);
  }, [release]);

  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setBackendError(false);
      setReportNotFound(false);

      const headers = { Authorization: jwtToken };
      const getIpmUrl = (r) => `/releases/${r.id}/reports/ipm`;

      return axios.get(`${baseApiUri}${getIpmUrl(release)}`, { params: {}, headers })
        .then(response => {
          setReport(response.data);
          setReportError(response.data.error);

          const dev = [];
          const test = [];
          for (let i = 0; i < response.data.current_effort.dev.length; i++) {
            dev.push({date: response.data.current_effort.dev[i]["date"], value: response.data.current_effort.dev[i]["effort"]})
          }
          for (let i = 0; i < response.data.current_effort.test.length; i++) {
            test.push({date: response.data.current_effort.test[i]["date"], value: response.data.current_effort.test[i]["effort"]})
          }


          setExistingEffortData({
            dev: dev,
            test: test
          });

        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        }) 
    }
  }, [release, jwtToken, baseApiUri]);

  React.useEffect(() => {
    if (!file) {
      setNewEffortData(null);
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        if (results.errors && results.errors.length > 0) {
          setPreviewError('Error parsing file');
          setNewEffortData(null);
          return;
        }

        const dev = [];
        const test = [];
        const effortList = [];

        for (let row of results.data) {
          const effort = {
            dev_date: row.dev_date ? parseDate(row.dev_date) : row.dev_date,
            dev_effort: row.dev_effort,
            test_date: row.test_date ? parseDate(row.test_date) : row.test_date,
            test_effort: row.test_effort,
          };

          effortList.push(effort);

          if (effort.dev_date) {
            dev.push({"date":effort.dev_date, "value":parseFloat(row.dev_effort)});
          }
          if (effort.test_date) {
            test.push({"date":effort.test_date, "value":parseFloat(row.test_effort)});
          }
        }

        setEffort(effortList);
        
        setNewEffortData({
          dev: dev,
          test: test
        });
      }
    });

  }, [file]);

  const handleSubmit = () => {
    if (release && file && file.name) {
      setError(false);
      setFormSubmitting(true);

      const url = `${baseApiUri}/releases/${release.id}/effort`;
      const headers = { Authorization: jwtToken };

      const formData = new FormData();
      formData.append("file", file, file.name);

      // return axios.post(url, effort, {headers})
      return axios.post(url, formData, {headers})
        .then(response => {
          setFormSubmitting(false);

        })
        .catch(error => {
          console.error(error);
          setError(true);
          setFormSubmitting(false);
        })
      }
    }

  const selectButtonProps = {
    color: "secondary",
    variant: "outlined",
    disabled: disabled || formSubmitting,
    className: classes.selectButton,
    component: "label"
  };

  const submitButtonProps = {
    color: "secondary",
    variant: "contained",
    type: "submit",
    disabled: formSubmitting || !file || !file.name,
    className: classes.submitButton,
  };

  const lines = [];

  if (existingEffortData && existingEffortData.dev.length != 0 && existingEffortData.test.length != 0) {
    lines.push({ id: 'Dev Effort (Existing)', data: existingEffortData.dev.map(obj => formatDatapoint(obj)), color: 'red', strokeStyle: 'solid', legend: true });
    lines.push({ id: 'Test Effort (Existing)', data: existingEffortData.test.map(obj => formatDatapoint(obj)), color: 'green', strokeStyle: 'solid', legend: true });
  }
  if (newEffortData) {
    lines.push({ id: 'Dev Effort (New)', data: newEffortData.dev, color: 'orange', strokeStyle: 'dashed', legend: true });
    lines.push({ id: 'Test Effort (New)', data: newEffortData.test, color: 'blue', strokeStyle: 'dashed', legend: true });
  }

  return (
      <SakuraCard title='Upload Effort Data' titleExtra={<a href='/assets/Template_Effort_Input_Data.csv' download>Download Sample Effort</a>}>
        <form onSubmit={ (e) => { handleSubmit(); e.preventDefault(); } }>
          <div>
            <Button {...selectButtonProps}>
              Choose CSV File
              <input type="file" onChange={(e) => setFile(e.target.files[0])} accept=".csv" hidden />
            </Button>
            <label className={classes.fileName}>{ file?.name ?? 'No file chosen' }</label>
          </div>

          <br/>

          <Box align='center' style={{flex: '0 0 100%'}}>
            <Button {...submitButtonProps}>
              {`Upload Effort`}
            </Button>
          </Box>
        </form>

        <br/>
        <br/>
        <Divider/>
        <br/>

        <LatestEffortUpload error={error} />

        <br/>

        <NivoChart 
          title={'Effort Data'}
          lines={lines}
          d1_date={parseDate(report?.d1_date)} 
          d2_date={parseDate(report?.d2_date)} 
          start_date={parseDate(report?.start_date)}
          chartHeight='15vw'
          allowHidingLines={true}
          allowHoverTooltip={true}
        />

      </SakuraCard>
  )
}

function mapStateToProps(state) {
  return {
    isAdmin: state.common.isAdmin,
    company: state.common.company,
    release: state.common.release,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(EffortUploader)


