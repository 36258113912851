import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports.js';

import Layout from './common/layout/main';
import { unifiedRoutes, mpmRoutes, ppmRoutes, ipmRoutes, prmRoutes, lpmRoutes, mpmExecutivePath } from './views/dashboard/routes';
import { configRoutes } from './views/user_inputs/routes';
import RouteNotFound from './views/misc/RouteNotFound';
import { trackerRoutes } from './views/tracker/routes';
import store from './store.js';
import UnderMaintainance from './views/misc/UnderMaintainance.js';

Amplify.configure(awsconfig);

function App() {
  const dashboardRoute = (path) => path;
  const demoRoute = (path) => `/demo${path}`;

  const renderRoutes = (routes) => (
    routes.map((route) => <Route exact path={dashboardRoute(route.path)} render={route.render} />)
  );
  const renderDemoRoutes = (routes) => (
    routes.map((route) => <Route exact path={demoRoute(route.path)} render={route.render} />)
  );

  return (
      <Layout>
        <Switch>

          {/* <Route path='*' exact component={UnderMaintainance} /> */}

          {/*{ renderRoutes(unifiedRoutes) }*/}
          { renderRoutes(mpmRoutes) }
          { renderRoutes(ppmRoutes) }
          { renderRoutes(ipmRoutes) }
          { renderRoutes(prmRoutes) }
          { renderRoutes(lpmRoutes) }
          { renderRoutes(configRoutes) }
          { renderRoutes(trackerRoutes) }

          { renderDemoRoutes(unifiedRoutes) }
          { renderDemoRoutes(mpmRoutes) }
          { renderDemoRoutes(ppmRoutes) }
          { renderDemoRoutes(ipmRoutes) }
          { renderDemoRoutes(prmRoutes) }
          { renderDemoRoutes(lpmRoutes) }
          { renderDemoRoutes(configRoutes) }

          <Route exact path="/">
            <Redirect to={mpmExecutivePath} />
          </Route>

          <Route path='*' exact component={RouteNotFound} />
        </Switch>
      </Layout>
  )
}

export default App;
