import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { openLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function BacklogChart(props) {
  const { backlog, d1_date, d2_date, start_date } = props;

  const lines = [];

  if (backlog) {
    if (backlog.actual_processed && backlog.actual_processed.length > 0) {
      const backlogActual = backlog.actual_processed.map(obj => formatDatapoint(obj));
      lines.push({ id: 'Open (Actual)', data: backlogActual, color: openLineColor, strokeStyle: 'solid', legend: true });
    }
    else if (backlog.actual && backlog.actual.length > 0) {
      const backlogActual = backlog.actual.map(obj => formatDatapoint(obj));
      lines.push({ id: 'Open (Actual)', data: backlogActual, color: openLineColor, strokeStyle: 'solid', legend: true });
    }
  }

  if (backlog && backlog.predicted && backlog.predicted.length > 0) {
    const backlogPredicted = backlog.predicted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Open (Predicted)', data: backlogPredicted, color: openLineColor, strokeStyle: 'dashed', legend: true });
  }

  if (backlog && backlog.upper_limit && backlog.upper_limit.length > 0) {
    const upperLimit = backlog.upper_limit.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Upper Limit', data: upperLimit, color: 'orange', strokeStyle: 'dashed', legend: true });
  }

  if (backlog && backlog.lower_limit && backlog.lower_limit.length > 0) {
    const lowerLimit = backlog.lower_limit.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Lower Limit', data: lowerLimit, color: 'orange', strokeStyle: 'dashed', legend: true });
  }

  return <NivoChart 
    title='Open Defect Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='24vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
