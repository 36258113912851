import React from 'react';
import DeliveryTable from './DeliveryTable';
import { formatNumber } from '../../../common/data_display/utils';

export default function FindTable(props) {
  const { metrics } = props;

  const tableProps = {
    title: 'Will we find enough defects?',
    redThreshold: 25,
    yellowThreshold: 15,
    rows: [
      { 
        name: 'Defects Remaining Until', 
        D1: metrics.defects_to_d1 ? formatNumber(metrics.defects_to_d1) : 'NA', 
        D2: metrics.defects_to_d2 ? formatNumber(metrics.defects_to_d2) : 'NA',
      },
      { 
        name: 'Defects Remaining After',
        D1: formatNumber(metrics.defects_remaining_d1),
        D2: formatNumber(metrics.defects_remaining_d2),
      },
      { 
        name: 'Percentage Residual Defects',
        D1: `${formatNumber(metrics.percentage_residual_d1)}%`,
        D2: `${formatNumber(metrics.percentage_residual_d2)}%`,
      },
    ],
  };

  return <DeliveryTable {...tableProps} />
}
