import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { secondaryMain } from '../../constants/colors';


const useStyles = makeStyles((theme) => ({
  modal: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 750,
    outline: 'none',
    border: 'none',
    background: 'white',
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
  modalTitle: {
    fontSize: 15,
    paddingBottom: 2,
    color: secondaryMain,
  },
  section: {
    paddingTop: 12,
  },
  gifImage: {
    maxWidth: '95%',
    maxHeight: '95vh',
    objectFit: 'contain',
  },
  gifSelectorContainer: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  gifSelectionDot: {
    height: 15,
    width: 15,
    margin: theme.spacing(0, 1),
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#888' },
    position: 'relative',
  },
  gifSelectorIndexText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
  },
  gifNavContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gifNavButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    marginBottom: '3px',
    margin: '0 20px',
  },
}));

const chartToolsGIF = [
    { file: process.env.PUBLIC_URL + '/gifs/hoverLines.gif', title: 'Line Hover Tooltip: ', text: 'When moving your cursor over a line on a graph, a small box will pop up next to it, showing you details about that specific point or section.' },
    { file: process.env.PUBLIC_URL + '/gifs/toggleLines.gif', title: 'Line Toggling: ', text: "By clicking on a line in the graph's legend, that particular line will appear or disappear on the graph, letting you focus on the data you're most interested in." }
];

const TextSection = (props) => {
  const classes = useStyles();
  const { title, text } = props;

  return (
    <Box className={classes.section}>
      <Typography><b>
        {title}
      </b></Typography>
      <Typography>
        {text}
      </Typography>
    </Box>
  )
}

export default function PopUpModal(props) {
  const classes = useStyles();
  const { children, open, onClose } = props;

  const [currentTutorialGIF, setCurrentTutorialGIF] = useState(0);

  const navLeft = () => { setCurrentTutorialGIF( currentTutorialGIF != 0 ? currentTutorialGIF - 1 : chartToolsGIF.length-1); }
  const navRight = () => { setCurrentTutorialGIF( currentTutorialGIF != chartToolsGIF.length-1 ? currentTutorialGIF + 1 : 0); }

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography className={classes.modalTitle} gutterBottom variant="h3">Interactive Chart Features Guide</Typography>
            <Divider />
            <TextSection title={chartToolsGIF[currentTutorialGIF].title} text={chartToolsGIF[currentTutorialGIF].text} />
            <div style={{display: "flex", justifyContent: "center"}}>
                <img className={classes.gifImage} src={chartToolsGIF[currentTutorialGIF].file} alt="Chart Tools GIF" />
            </div>
            <div className={classes.gifNavContainer}>
                <button className={classes.gifNavButton} onClick={navLeft}>&lt;</button>

                <div className={classes.gifSelectorContainer}>
                    {chartToolsGIF.map((_, index) => (
                        <span
                            key={index}
                            className={classes.gifSelectionDot}
                            style={index === currentTutorialGIF ? { backgroundColor: secondaryMain, color: secondaryMain } : {}}
                            onClick={() => setCurrentTutorialGIF(index)}
                        >
                          <span className={classes.gifSelectorIndexText}>{index + 1}</span>
                        </span>
                    ))}
                </div>

                <button className={classes.gifNavButton} onClick={navRight}>&gt;</button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}