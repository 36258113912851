import React from 'react';
import InfoModal from '../../../common/data_display/InfoModal';

export const ExecutiveInfoSections = [
  { title: 'D1', text: 'Delivery for trial.*' },
  { title: 'D2', text: 'Delivery for commercial deployment.*' },
  { title: 'Defects', text: 'Frequently referred to as faults or bugs, a defect represents an error, flaw, or failure in a computer program or system that causes it to produce an incorrect or unexpected result, or to behave in an unintended way.' },
  { title: 'Arrival', text: 'Defects found or reported by testers or customers.' },
  { title: 'Closure', text: 'Software fixes completed and verified.' },
  { title: 'Open Defects', text: 'Defects that are still open - i.e. Arrival - Closure'},
  { title: '', text: ''},
  { title: ' ', text: '* To facilitate prediction, dates may not align exactly with dates set in configuration.' }
];

export default function ExecutiveInfoModal(props) {
  const { open, onClose } = props;

  const modalProps = {
    title: 'Executive Summary Info',
    open: open,
    onClose: onClose,
    textSections: ExecutiveInfoSections,
  };

  return <InfoModal {...modalProps} />
}