import React from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InfoButton from '../../../common/data_display/InfoButton';
import InfoModal from '../../../common/data_display/InfoModal';
import { useTableStyles } from '../../../common/styles/TableStyles';
import { makeStyles } from '@material-ui/core';
import { primaryMedium, secondaryMain } from '../../../constants/colors';


export default function DeliveryReadyTable(props) {
  const classes = useTableStyles();
  const { metrics } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const getColorClass = (row, value) => {
    if (isNaN(value)) return null;

    if (value > row.redThreshold ) {
      return classes.redCell;
    }
    else if (value >= row.yellowThreshold ) {
      return classes.yellowCell;
    }
    else {
      return classes.greenCell;
    }
  }

  const title = 'Will we be ready for delivery on time with acceptable quality?';
  const rows = [
    { 
      body: <React.Fragment><b>Percentage Residual Defects</b> <i>(Will we find enough defects?)</i></React.Fragment>,
      d1: metrics.percentage_residual_d1,
      d2: metrics.percentage_residual_d2,
      redThreshold: 25,
      yellowThreshold: 15,
    },
    { 
      body: <React.Fragment><b>Percentage Open Defects</b> <i>(Will we fix enough defects?)</i></React.Fragment>, 
      d1: metrics.pct_open_at_d1,
      d2: metrics.pct_open_at_d2,
      redThreshold: 10,
      yellowThreshold: 5,
    },
  ];

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom variant="h3">
            {title}
          </Typography>
          
          <div className={classes.infoButton}>
            <InfoButton onClick={handleModalOpen} />
          </div>

          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow className={classes.head}>
                <TableCell className={clsx(classes.headCell, classes.rowValue)} component="th" scope="row" align="center"></TableCell>
                <TableCell className={clsx(classes.headCell, classes.rowValue)} align="center">
                  D1
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.rowValue)} align="center">
                  D2
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row) => {
                const leftClass = clsx(getColorClass(row, row.d1), classes.thresholdCellLeft);
                const rightClass = getColorClass(row, row.d2);

                return (
                  <TableRow>
                    <TableCell component="th" scope="row" align="center" className={clsx(classes.cell, classes.nameCell, classes.rowName)}>
                      {row.body}
                    </TableCell>
                    <TableCell align="center" className={clsx(classes.cell, classes.valueCell, leftClass)}>
                      <b>{row.d1}%</b>
                    </TableCell>
                    <TableCell align="center" className={clsx(classes.cell, classes.valueCell, rightClass)}>
                      <b>{row.d2}%</b>
                    </TableCell>
                  </TableRow>
              )})}
            </TableBody>
          </Table>

        </CardContent>
      </Card>

      <InfoModal title={title} open={modalOpen} onClose={handleModalClose}>
        <KeyTable tableStyles={useTableStyles()} />
      </InfoModal>
    </React.Fragment>
  );

}


export const KeyTable = (props) => {
  const { tableStyles } = props;
  const classes = tableStyles;

  return (
    <div>
      <Table size="small" className={clsx(classes.table, classes.thresholdTable)}>
        <TableHead>
          <TableRow className={classes.head}>
            <TableCell className={classes.headCell} align="center">
              Color
            </TableCell>
            <TableCell className={classes.headCell} align="center">
              Meaning
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.redCell, classes.thresholdCellLeft)}>
              Red
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              At Risk
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.yellowCell, classes.thresholdCellLeft)}>
              Yellow
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              Warning
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.greenCell, classes.thresholdCellLeft)}>
              Green
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
              Acceptable
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      
      <br/>
      <div>
        <b style={{color: secondaryMain }}>Note:</b>
        <br/> 
        <b>Percentage Residual Defects</b> = Defects Remaining After / Total Defects
        <br/>
        <b>Percentage Open</b> = Open Defects / Defects Arrived
      </div>
    </div>
  )
}
