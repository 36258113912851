import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { secondaryMain, primaryLightest, redCell, greenCell, yellowCell, grey, primaryMedium } from '../../../constants/colors';

import InfoButton from '../../../common/data_display/InfoButton';
import InfoModal from '../../../common/data_display/InfoModal';
import { useTableStyles } from '../../../common/styles/TableStyles';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    border: `1px solid ${grey}`,
  },
  table: {
    height: '100%',
    background: 'white',
  },
  head: {
    background: primaryMedium,
    borderBottom: `1px solid ${grey}`,
  },
  headTypography: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'white',
  },
  cell: {
    border: 'none',
  },
  nameCell: {
    background: primaryLightest,
    borderRight: `1px solid ${grey}`,
  },
  nameTypography: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  typeTypography: {
    fontSize: 12,
  },
  bottomBorder: {
    borderBottom: `1px solid ${grey}`,
  },
  rightBorder: {
    borderRight: `1px solid ${grey}`,
  },
  redCell: {
    background: redCell,
  },
  yellowCell: {
    background: greenCell,
  },
  greenCell: {
    background: yellowCell,
  },
});


function ActionRow(props) {
  const classes = useStyles();
  const { metricName, metricType, d1, d2, applyBottomBorder, redThreshold, yellowThreshold } = props;

  const getColorClass = (value) => {
    const floatValue = parseFloat(value);

    if (floatValue > redThreshold ) {
      return classes.redCell
    }
    else if (floatValue >= yellowThreshold ) {
      return classes.yellowCell
    }
    else {
      return classes.greenCell
    }
  }

  return (
    <TableRow className={clsx(classes.row, {[classes.bottomBorder]: applyBottomBorder})}>
      <TableCell className={clsx(classes.cell, classes.nameCell)} component="th" scope="row" align="center">
        <Typography className={classes.nameTypography}>{metricName}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.rightBorder)} align="center">
        <Typography className={classes.typeTypography}>{metricType}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.rightBorder, getColorClass(d1))} align="center">
        <Typography >{d1}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, getColorClass(d2))} align="center">
        <Typography>{d2}</Typography>
      </TableCell>
    </TableRow>
  )
}


export default function ActionTable(props) {
  const classes = useStyles();
  const { metrics, correctiveAction } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

    const rows = [
      { metricName: '% Residual Defects', metricType: 'Current', d1: metrics.percentage_residual_d1, d2: metrics.percentage_residual_d2, redThreshold: 25, yellowThreshold: 15 },
      { metricName: '', metricType: 'Adjusted', d1: correctiveAction.delayed_delivery_output.pct_residual_d1, d2: correctiveAction.delayed_delivery_output.pct_residual_d2, redThreshold: 25, yellowThreshold: 15 },
      { metricName: '% Open Defects', metricType: 'Current', d1: metrics.pct_open_at_d1, d2: metrics.pct_open_at_d2, redThreshold: 10, yellowThreshold: 5 },
      { metricName: '', metricType: 'Adjusted', d1: correctiveAction.delayed_delivery_output.pct_open_d1, d2: correctiveAction.delayed_delivery_output.pct_open_d2, redThreshold: 10, yellowThreshold: 5 },
    ];

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Table className={classes.table} size="small">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.cell} align="center">
                <div style={{display:"flex", alignItems:"center"}}>
                  <InfoButton onClick={handleModalOpen} />
                  <div style={{flex:1, textAlign:"center"}}>
                    <Typography className={classes.headTypography}>
                      Metric
                    </Typography>
                  </div>
                </div>
              </TableCell>
              <TableCell className={classes.cell} component="th" scope="row"/>
              <TableCell className={classes.cell} align="center">
                <Typography className={classes.headTypography}>
                  D1
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} align="center">
                <Typography className={classes.headTypography}>
                  D2
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { rows.map((row, index) => <ActionRow applyBottomBorder={index===1} {...row} />) }
          </TableBody>
        </Table>
      </Card>

      <InfoModal title={"Will we be ready for delivery on time with acceptable quality?"} open={modalOpen} onClose={handleModalClose}>
        <KeyTable />
        <br/>
        <div>
          <b style={{color: secondaryMain }}>Note:</b>
          <br/> 
          <b>Percentage Residual Defects</b> = Defects Remaining After / Total Defects
          <br/>
          <b>Percentage Open</b> = Open Defects / Defects Arrived
        </div>
      </InfoModal>
    </React.Fragment>
  );
}

const KeyTable = () => {
  const classes = useTableStyles();

  return (
    <Table size="small" className={clsx(classes.table, classes.thresholdTable)}>
      <TableHead>
        <TableRow className={classes.head}>
          <TableCell className={classes.headCell} align="center">
            Color
          </TableCell>
          <TableCell className={classes.headCell} align="center">
            Meaning
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.redCell, classes.thresholdCellLeft)}>
            Red
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            At Risk
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.yellowCell, classes.thresholdCellLeft)}>
            Yellow
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            Warning
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell, classes.greenCell, classes.thresholdCellLeft)}>
            Green
          </TableCell>
          <TableCell align="center" className={clsx(classes.cell, classes.valueCell)}>
            Acceptable
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}