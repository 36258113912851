import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatDate, formatNumber } from '../../../common/data_display/utils';
import { useTableStyles } from '../../../common/styles/TableStyles';


export default function DatesTable(props) {
  const { d1_date, d2_date, ppm_metrics, metrics } = props;
  const classes = useTableStyles();
  const headerClasses = clsx(classes.headCell, classes.head);

  const selectedMetrics = ppm_metrics ? ppm_metrics : metrics;

  return (
    <Card>
      <CardContent className={classes.content}>
        <Table size="small" aria-label="table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={headerClasses} colSpan={2} align="center">
                Dates
              </TableCell>
              <TableCell className={headerClasses} align="center">
                Predicted defects (a)          
              </TableCell>
              <TableCell className={headerClasses} align="center">
                Target defects (b)
              </TableCell>
              <TableCell className={headerClasses} align="center">
                Difference (a) - (b)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key="d1-row">
              <TableCell className={classes.cell}>
                D1
              </TableCell>
              <TableCell className={classes.cell}>
                {d1_date ? formatDate(new Date(d1_date)) : undefined}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.predicted_defects_d1}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.target_predicted_defects_d1}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.predicted_defects_d1 - selectedMetrics.target_predicted_defects_d1}
              </TableCell>
            </TableRow>
            <TableRow key="d2-row">
              <TableCell className={classes.cell}>
                D2
              </TableCell>
              <TableCell className={classes.cell}>
                {d2_date ? formatDate(new Date(d2_date)) : undefined}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.predicted_defects_d2}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.target_predicted_defects_d2}
              </TableCell>
              <TableCell className={classes.cell} align="center">
                {selectedMetrics.predicted_defects_d2 - selectedMetrics.target_predicted_defects_d2}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}