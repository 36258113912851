import React from 'react';
import { NumberInput } from '../fields';

export default function MpmNested(props) {
  const { config, onChange, disabled } = props;

  const fields = [
    { label: 'Accuracy Initial', value: config.accuracy_initial, onChange: onChange('accuracy_initial') },
    { label: 'Accuracy Step', value: config.accuracy_step, onChange: onChange('accuracy_step') },
    { label: 'b[0]', value: config.b_0, onChange: onChange('b_0') },
    { label: 'b Max', value: config.b_max, onChange: onChange('b_max') },
    { label: 'b Min', value: config.b_min, onChange: onChange('b_min') },
    { label: 'Iteration Limit', value: config.iteration_limit, onChange: onChange('iteration_limit') },
    { label: 'Min Data Points Required', value: config.min_data_points_required, onChange: onChange('min_data_points_required') },
    { label: 'Level off N Data Points Pre-D2', value: config.level_off_n_data_points_pre_d2, onChange: onChange('level_off_n_data_points_pre_d2') },
    { label: 'Percentage Residual Target @ D2', value: config.target_percentage_residual_at_d2, onChange: onChange('target_percentage_residual_at_d2') },
    { label: 'Min Improvement Per Iteration', value: config.min_improvement_per_iteration, onChange: onChange('min_improvement_per_iteration') },
  ];

  if (config.percentage_incr_threshold !== undefined) {
    fields.push({ label: '% Increment Threshold', value: config.percentage_incr_threshold, onChange: onChange('percentage_incr_threshold') });
  }

  return (
   <React.Fragment>
    { fields.map((field) => <NumberInput disabled={disabled} required={true} {...field} />) }
   </React.Fragment>
  )
}
