import React from 'react';
import DataTable from '../../../common/data_display/DataTable';
import { formatDate, formatNumber } from '../../../common/data_display/utils';


export default function DatesTable(props) {
  const { d1_date, d2_date, timestamp, metrics } = props;

  const rows = [
    { name: 'Report Date' , value: timestamp ? formatDate(new Date(timestamp)) : undefined },
    { name: 'D1 Date', value: d1_date ? formatDate(new Date(d1_date)) : undefined },
    { name: 'D2 Date', value: d2_date ? formatDate(new Date(d2_date)) : undefined },
    { name: 'Target Defects @ D2', value: formatNumber(metrics.predicted_defects_d2) },
  ];

  return <DataTable title='Dates' rows={rows} />
}