import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {primaryLightest, backgroundDark} from '../../../constants/colors';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  list: {
    borderTop: `1px solid ${backgroundDark}`,
    borderBottom: `1px solid ${backgroundDark}`,
    borderRight: 0,
    borderLeft: 0,
    '&>:first-child': {
      borderTop: `1px solid ${backgroundDark}`,
    },
    background: primaryLightest
  },
  listItem: {
    paddingBottom: 1,
    paddingRight: 1,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: '32px',
  },
  listItemTooltip: {
    fontSize: 14,
  },
}));

export default function NestedMenu(props) {
  const { label, icon, items, expanded, onClick, tooltipText } = props;
  const classes = useStyles();

  const listItemTextProps = {
    primary: label,
    classes: {
      primary: classes.listItemText,
    },
  };

  const listItem = tooltipText ? 
    (
      <Tooltip classes={{ tooltip: classes.listItemTooltip }} title={tooltipText} aria-label={tooltipText} placement='right'>
        <ListItemText {...listItemTextProps} />
      </Tooltip>
    ) : (
      <ListItemText {...listItemTextProps} />
    );


  return (
    <div className={classes.root}>
      <ListItem onClick={onClick} className={classes.listItem}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        {listItem}
        <ListItemIcon>
          { expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
        </ListItemIcon>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit disablePadding>
        <List className={classes.list}>
          {items}
        </List>
      </Collapse>
    </div>
  )
}