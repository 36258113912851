import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor, closureLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function CumulativeChart(props) {
  const { arrival, closure, d1_date, d2_date, start_date, inputValue, 
    delayed_delivery_output, arrival_adjusted, closure_adjusted } = props;

  const lines = [];

  const hasInput = inputValue && (
    inputValue?.contentValue > 0 ||
    inputValue?.developersValue > 0 ||
    inputValue?.testersValue > 0
  );

  if (hasInput && arrival_adjusted && arrival_adjusted.length > 0) {
    const adjustedArrival = arrival_adjusted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Adj)', data: adjustedArrival, color: 'orange', strokeStyle: 'dashed', keyOrder: 5, legend: true });
  }

  if (hasInput && closure_adjusted && closure_adjusted.length > 0) {
    const adjustedClosure = closure_adjusted.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure (Adj)', data: adjustedClosure, color: 'lightgreen', strokeStyle: 'dashed', keyOrder: 6, legend: true });
  }

  if (arrival && arrival.actual_processed && arrival.actual_processed.length > 0) {
    const arrivalActual = cumulateDatapoints(arrival.actual_processed).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', keyOrder: 1, legend: true });
  }

  if (closure && closure.actual_processed && closure.actual_processed.length > 0) {
    const closureActual = cumulateDatapoints(closure.actual_processed).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure', data: closureActual, color: closureLineColor, strokeStyle: 'solid', keyOrder: 2, legend: true });
  }

  if (arrival && arrival.predicted && arrival.predicted.length > 0) {
    const arrivalPredicted = cumulateDatapoints(arrival.predicted).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Predicted)', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', keyOrder: 3, legend: true });
  }

  if (closure && closure.predicted && closure.predicted.length > 0) {
    const closurePredicted = cumulateDatapoints(closure.predicted).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Closure (Predicted)', data: closurePredicted, color: closureLineColor, strokeStyle: 'dashed', keyOrder: 4, legend: true });
  }

  return <NivoChart 
    title='Cumulative Defect Prediction' 
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    delayed_D1={inputValue?.delayValue > 0 && delayed_delivery_output ? new Date(delayed_delivery_output.new_d1) : undefined}
    delayed_D2={inputValue?.delayValue > 0 && delayed_delivery_output ? new Date(delayed_delivery_output.new_d2) : undefined}
    chartHeight='24vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
