import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import SakuraCard from '../../../common/layout/SakuraCard';
import InfoButton from '../../../common/data_display/InfoButton';
import InfoModal from '../../../common/data_display/InfoModal';
import { useAlert } from '../../../common/data_display/alerts/AlertContext';
import { DateInput, NumberInput, ReleaseSelect } from "../fields";
import { SubmitButton } from '../../../common/forms/buttons';
import { primaryLightest, secondaryMain } from '../../../constants/colors';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    marginBottom: 16,
    marginTop: 0,
  },
  label: {
    fontWeight: 'bold',
    paddingLeft: 14,
  },
  input: {
    padding: '10px 14px',
    background: primaryLightest,
    border: '1px solid #c4c4c4',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  button: {
    width: 280,
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
  success: {
    // color: '#15D2A7',
    fontWeight: 'bold',
  },
  submitButton: {
    marginTop: 16,
  },
}));

const SubheadingTitle = styled(Typography)`
  display: inline-block;
  padding-bottom: 2px;
  margin-top: 16px;
  color: ${secondaryMain};
`;

const InfoButtonWrapper = styled.div`
  display: inline-block;
  margin-top: -8px;
`;

const SpacedDivider = styled(Divider)`
  margin-bottom: 24px;
`;

export default function ReleaseConfig(props) {
  const classes = useStyles();
  const { release, releaseList, isAdmin, jwtToken, baseApiUri, company } = props;

  const readonly = (company && company.id === 1) && !isAdmin;

  const getUrl = (r) => `${baseApiUri}/releases/${r.id}/config/release`;
  

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [config, setConfig] = React.useState();
  const [error, setError] = React.useState();
  const [message, setMessage] = React.useState();
  const [disabled, setDisabled] = React.useState(readonly);

  const { sendAlert } = useAlert();
  const saveConfigRef = React.useRef();

  function Subheader({title, children, modalProps = undefined}) { 
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false); 
  
    const finalModalProps = modalProps ? { ...modalProps, open: modalOpen, onClose: handleModalClose } : undefined;
  
    return (
      <>
        <SubheadingTitle gutterBottom variant="h3">
          {title}
        </SubheadingTitle>
        {finalModalProps ? 
          ( 
            <InfoButtonWrapper>
              <InfoButton onClick={handleModalOpen} />
            </InfoButtonWrapper>
          )
          :
          <></>
        }
        <SpacedDivider />

        {children}
        
        {finalModalProps ? <InfoModal {...finalModalProps} /> : <></>}
      </>
    );
  }

  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (!release) return;

    setConfig(undefined);
    setError(undefined);
    setMessage(undefined);
    setDisabled(true);

    const headers = { Authorization: jwtToken };

    axios.get(getUrl(release), {headers})
      .then(response => {
        setConfig(response.data);
        setDisabled(readonly);
      })
      .catch(error => {
        console.log(error);
        setError('There was an error while loading release configuration.');
        setDisabled(readonly);
      })
  }, [release, jwtToken]);

  // --------------------------- Event Handlers ---------------------------- //
  // ----------------------------------------------------------------------- //  
  const handleDateChange = (field) => (value) => setConfig({
    ...config, 
    [field]: value.toISOString().slice(0, 10),
  });

  const handleNumberChange = (field) => (e) => setConfig({
    ...config, 
    [field]: e.target.value ? Number(e.target.value) : null,
  });

  const handleSubmit = () => {
    setError(undefined);
    setMessage(undefined);
    setDisabled(true);

    const headers = { Authorization: jwtToken };

    axios.put(getUrl(release), config, {headers})
      .then(response => {
        setConfig(response.data);
        setDisabled(readonly);

        if (response.status === 200) {
          const rect = saveConfigRef.current.getBoundingClientRect();
          sendAlert({
            severity: 'success',
            title: 'Success!',
            message: 'Your selection inputs were saved.',
            duration: 5,
            style: { top: `${rect.bottom + window.scrollY + 15}px`, left: `${rect.left + window.scrollX + rect.width / 2 + 18}px`, transform: `translateX(-50%)` }
          });
        }
      })
      .catch(error => {
        console.log(error);
        setError('There was an error while updating release configuration.');
        setDisabled(readonly);
      })
  }

  // ----------------------------- Modal Data ------------------------------ //
  // ----------------------------------------------------------------------- //
  const projectMilestoneModal = {
    title: 'Project Milestone Descriptions',
    textSections: [
      { title: 'Start Date', text : 'Project Start Date' },
      { title: 'D1', text: 'Delivery Date for Trial*' },
      { title: 'D2', text: 'Delivery Date for Commercial Deployment*' },
      { title: '', text: ''},
      { title: '', text: '* To facilitate prediction, dates may not align exactly with dates set in configuration.'}
    ]
  };

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  return (
    <form onSubmit={ (e) => { e.preventDefault(); handleSubmit(); e.preventDefault(); } }>
      { config && (
        <SakuraCard title='Project Milestones' modalProps={projectMilestoneModal}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateInput label='Start Date' value={config.start_date} onChange={handleDateChange('start_date')} required={true} disabled={disabled} />
            <DateInput label='D1 Date' value={config.d1_date} onChange={handleDateChange('d1_date')} required={true} disabled={disabled} />
            <DateInput label='D2 Date' value={config.d2_date} onChange={handleDateChange('d2_date')} required={true} disabled={disabled} />
            {/* <br/><br/> */}
            {/* <ReleaseSelect label='Prior Release (PRM)' value={config.prm_prior_release_id} onChange={handleNumberChange('prm_prior_release_id')} options={releaseList.filter(item => item.id !== release.id)} disabled={disabled} /> */}
          </MuiPickersUtilsProvider>

          { isAdmin && (
            <React.Fragment>
              <br/><br/><Divider/><br/><br/>
              <NumberInput label='Increment' value={config.increment} onChange={handleNumberChange('increment')} disabled={disabled} />
              <NumberInput label='Prediction Horizon' value={config.prediction_horizon} onChange={handleNumberChange('prediction_horizon')} required={true} disabled={disabled} />
            </React.Fragment>
          )}

          <Subheader title='Prior Release Data'>
            <ReleaseSelect label='Choose Release' value={config.prior_release_id} onChange={handleNumberChange('prior_release_id')} options={releaseList.filter(item => item.id !== release.id)} disabled={disabled} />
          </Subheader>

          <Box align='center'>
            {message && (<p className={classes.success}>{message}</p>)}
            {error && (<p className={classes.error}>{error}</p>)}
            <SubmitButton className={classes.submitButton} disabled={disabled} ref={saveConfigRef} />
          </Box>
        </SakuraCard>
      )}
    </form>
  )
}

