import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { secondaryMain } from '../../constants/colors';
import { useTableStyles } from '../styles/TableStyles';
import { ExecutiveInfoSections } from '../../views/dashboard/executive_view/ExecutiveInfoModal'
import { ThresholdTable } from '../../views/dashboard/executive_view/DeliveryTable';
import { KeyTable } from '../../views/dashboard/executive_view/DeliveryReadyTable';

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 600,
    outline: 'none',
    border: 'none',
    background: 'white',
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
  modalTitle: {
    fontSize: 15,
    paddingBottom: 2,
    color: secondaryMain,
  },
  section: {
    paddingTop: 12,
  },
  navSelectorContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  navSelectionDot: {
    height: 15,
    width: 15,
    margin: theme.spacing(0, 1),
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#888' },
    position: 'relative',
  },
  navSelectorIndexText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    marginBottom: '0px',
    margin: '0 20px',
  },
  modalButtonContainer: {
    flex: '0 0 100%',
    marginTop: 12,
    marginBottom: -12,
  },
  guideImage: {
    maxWidth: '95%',
    maxHeight: '95vh',
    objectFit: 'contain',
  },
}));

const TextSection = (props) => {
  const classes = useStyles();
  const { title, text } = props;

  return (
    <Box className={classes.section}>
      <Typography variant='h3'>
        {title}
      </Typography>
      <Typography>
        {text}
      </Typography>
    </Box>
  )
}

export default function GuideInfoModal(props) {
  const classes = useStyles();
  const { title, children, open, onClose, closeModalProps } = props;

  const STARDemoGuide = [
    { title: title, text: children, isTextSectioned: false },
    { title: 'Executive Summary Info', text: ExecutiveInfoSections, isTextSectioned: true },
    { title: 'Will we be ready for delivery on time with acceptable quality?', text: KeyTable({ tableStyles: useTableStyles() }), isTextSectioned: false },
    { title: 'Will we find enough defects? (Residual)', text: ThresholdTable({redThreshold: 25, yellowThreshold: 15, tableStyles: useTableStyles() }), isTextSectioned: false },
    { title: 'Will we find enough defects? (Open)', text: ThresholdTable({redThreshold: 10, yellowThreshold: 5, tableStyles: useTableStyles() }), isTextSectioned: false },
    { title: 'Interactive Chart Features', text: [{title: 'Line Hover Tooltip:', text: 'When moving your cursor over a line on a graph, a small box will pop up next to it, showing you details about that specific point or section.'}], image: process.env.PUBLIC_URL + '/gifs/hoverLines.gif', isTextSectioned: true},
    { title: 'Interactive Chart Features', text: [{title: 'Line Toggling:', text: "By clicking on a line in the graph's legend, that particular line will appear or disappear on the graph, letting you focus on the data you're most interested in."}], image: process.env.PUBLIC_URL + '/gifs/toggleLines.gif', isTextSectioned: true},
  ];
  const [currentGuidePage, setCurrentGuidePage] = useState(0);

  const navLeft  = () => { setCurrentGuidePage( currentGuidePage != 0 ? currentGuidePage - 1 : currentGuidePage); }
  const navRight = () => { (currentGuidePage === STARDemoGuide.length-1) ? onClose() : setCurrentGuidePage(currentGuidePage + 1); }

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography className={classes.modalTitle} gutterBottom variant="h3">{STARDemoGuide[currentGuidePage].title}</Typography>
            <Divider />
            { STARDemoGuide[currentGuidePage].isTextSectioned ? 
                STARDemoGuide[currentGuidePage].text.map((section) => <TextSection title={section.title} text={section.text} />) 
                : 
                STARDemoGuide[currentGuidePage].text }

            { STARDemoGuide[currentGuidePage].image ?
              <div style={{display: "flex", justifyContent: "center"}}>
                <img className={classes.guideImage} src={STARDemoGuide[currentGuidePage].image} alt="Chart Tools GIF" />
              </div>
              : <></>
            }

            <div className={classes.navContainer}>
              <button className={classes.navButton} onClick={navLeft}>&lt;</button>
              <div className={classes.navSelectorContainer}>
                {STARDemoGuide.map((_, index) => (
                  <span
                    key={index}
                    className={classes.navSelectionDot}
                    style={index === currentGuidePage ? { backgroundColor: secondaryMain, color: secondaryMain } : {}}
                    onClick={() => setCurrentGuidePage(index)}
                  >
                    <span className={classes.navSelectorIndexText}>{index + 1}</span>
                  </span>
                ))}
              </div>
              <button className={classes.navButton} onClick={navRight}>&gt;</button>
            </div>

            <div align='center' className={classes.modalButtonContainer}>
              <Button {...closeModalProps} style={{ width: 'fit-content' }}>
                {`Skip Guide`}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}