import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function EarlyPredictionChart(props) {
  const { arrival, ppm_prediction, ppm_metrics, metrics, d1_date, d2_date, start_date } = props;

  const selectedMetrics = ppm_metrics ? ppm_metrics : metrics;
  const { target_predicted_defects_d1, target_predicted_defects_d2 } = selectedMetrics;

  const lines = [];

  if (arrival && arrival.actual_processed && arrival.actual_processed.length > 0) {
    const arrivalActual = cumulateDatapoints(arrival.actual_processed).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Actual', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', legend: true });
  }

  // ppm_prediction is where the PPM prediction results are stored for the IPM report.
  // If this is undefined, then we know that the input is a PPM report, meaning that the
  // PPM prediction is stored in arrival.
  if (ppm_prediction && ppm_prediction.arrival) {
    if (ppm_prediction.arrival.predicted && ppm_prediction.arrival.predicted.length > 0) {
      const arrivalPredicted = cumulateDatapoints(ppm_prediction.arrival.predicted).map(obj => formatDatapoint(obj));
      lines.push({ id: 'PPM Prediction', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', legend: true });
    }

    if (ppm_prediction.arrival.upper_limit && ppm_prediction.arrival.upper_limit.length > 0) {
      const upperLimit = cumulateDatapoints(ppm_prediction.arrival.upper_limit).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Upper Limit', data: upperLimit, color: 'darkblue', strokeStyle: 'dashed', legend: true });
    }

    if (ppm_prediction.arrival.lower_limit && ppm_prediction.arrival.lower_limit.length > 0) {
      const lowerLimit = cumulateDatapoints(ppm_prediction.arrival.lower_limit).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Lower Limit', data: lowerLimit, color: 'darkgreen', strokeStyle: 'dashed', legend: true });
    }
  }

  else if (arrival) {
    if (arrival.predicted && arrival.predicted.length > 0) {
      const arrivalPredicted = cumulateDatapoints(arrival.predicted).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Prediction', data: arrivalPredicted, color: arrivalLineColor, strokeStyle: 'dashed', legend: true });
    }

    if (arrival.upper_limit && arrival.upper_limit.length > 0) {
      const upperLimit = cumulateDatapoints(arrival.upper_limit).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Upper Limit', data: upperLimit, color: 'darkblue', strokeStyle: 'dashed', legend: true });
    }

    if (arrival.lower_limit && arrival.lower_limit.length > 0) {
      const lowerLimit = cumulateDatapoints(arrival.lower_limit).map(obj => formatDatapoint(obj));
      lines.push({ id: 'Lower Limit', data: lowerLimit, color: 'darkgreen', strokeStyle: 'dashed', legend: true });
    }
  }


  const scatters = d1_date && d2_date && target_predicted_defects_d1 && target_predicted_defects_d2 ? [    
    {
      id: 'User Input',
      data: [
        {
          date: new Date(d1_date),
          value: target_predicted_defects_d1,
        },
        {
          date: new Date(d2_date),
          value: target_predicted_defects_d2,
        },
      ],
      strokeStyle: 'solid',
      color: 'black',
      fill: 'darkred',
      points: true,
      legend: true,
    }
  ] : [];
  
  return <NivoChart 
    title='Early Defect Prediction' 
    lines={lines}
    scatters={scatters}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    start_date={parseDate(start_date)}
    chartHeight='30vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
