import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './header/main';
import Sidebar from './sidebar/main';
import { SET_USER_AUTHENTICATED } from '../redux/actionTypes';
import { activateDemo, deactivateDemo, initializeApp } from '../redux/actions';
import store from '../../store';
import { headerHeight } from '../../constants/dimensions';
import Button from '@material-ui/core/Button';
import { secondaryMain, primaryMedium } from '../../constants/colors';
import { useLocalStorage } from '../hooks/UseLocalStorage';
import InfoModal from '../data_display/InfoModal';
import GuideInfoModal from '../data_display/GuideInfoModal';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  main: {
    paddingTop: headerHeight + 24,
    paddingBottom: 12,
    paddingLeft: 96,
    paddingRight: 24,
  },
  infoBox: {
    width: 480,
    background: `linear-gradient(45deg, ${secondaryMain} 0%, ${primaryMedium} 100%)`,
    margin: '0 auto',
    marginTop: -12,
    marginBottom: 12,
    color: 'white',
    fontSize: 16,
    height: 32,
    padding: 0,
    lineHeight: '32px',
    display: 'block',

    '&:hover': {
      background: `linear-gradient(45deg, ${primaryMedium} 0%, ${secondaryMain} 100%)`,
      fontWeight: 'bold',
    },
  },
  modalButtonContainer: {
    flex: '0 0 100%',
    marginBottom: -12,
  },
  closeModalButton: {
    width: 200,
  },
}));


function Layout(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { children, isAuthenticated, demoEnabled, showTrialButton, company, company_expired, no_companies } = props;

  const [hasSeenDemoModal, setHasSeenDemoModal] = useState(sessionStorage.getItem('hasSeenDemoModal') === 'true');

  React.useEffect(() => {
    const startsWithDemo = location.pathname.startsWith('/demo');
    if (startsWithDemo && !hasSeenDemoModal) {
      setModalOpen(true);
    }
  }, [location, hasSeenDemoModal]);

  const [hasClearedAccountModal, setHasClearedAccountModal] = useState(false);

  React.useEffect(() => {
    const startsWithDemo = location.pathname.startsWith('/demo');
    store.dispatch(startsWithDemo ? activateDemo() : deactivateDemo());
  }, [location]);

  React.useEffect(() => {
    const authenticateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        user.refreshSession(session.refreshToken, (err, session) => console.log('Token Refreshed'));
        const { payload } = user.signInUserSession.accessToken;
        const isAdmin = (payload['cognito:groups'] && payload['cognito:groups'].includes('ADMIN'));
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        store.dispatch({ type: SET_USER_AUTHENTICATED, isAuthenticated: true, isAdmin: isAdmin, jwtToken: jwtToken })
      }
      catch (error) {
        const authUrl = `${process.env.REACT_APP_AUTH_URL}?next=${window.location.href}`;
        window.location = authUrl;
      }
    };

    if (demoEnabled === false ) {
      authenticateUser();
    } 
  }, [demoEnabled]);

  React.useEffect(() => {
    if (isAuthenticated || demoEnabled) {
      store.dispatch(initializeApp());
    }
  }, [isAuthenticated, demoEnabled]);

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalClose = () => {
    sessionStorage.setItem('hasSeenDemoModal', 'true');
    setHasSeenDemoModal(true);
    setModalOpen(false);
  }

  React.useEffect(() => {
    const startsWithDemo = location.pathname.startsWith('/demo');
    if (startsWithDemo && !hasSeenDemoModal) {
      setModalOpen(true);
    }
  }, [location, hasSeenDemoModal]);

  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const handleAccountModalClose = () => {
    setHasClearedAccountModal(true);
    setAccountModalOpen(false);
  }

  React.useEffect(() => {
    setAccountModalOpen(company_expired || no_companies);
  }, [company_expired, no_companies]);

  React.useEffect(() => {
    setHasClearedAccountModal(false);
  }, [company, company_expired, no_companies]);

  const closeModalProps = {
    color: "secondary",
    variant: "contained",
    type: "submit",
    className: classes.closeModalButton,
    onClick: handleModalClose
  };

  useEffect(() => {
    if (hasSeenDemoModal && location.hash === '#reset-has-seen-demo') {
      setHasSeenDemoModal(false);
      history.push({
        ...location,
        hash: '',
      })
    }
  }, [hasSeenDemoModal, setHasSeenDemoModal, location, history]);
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Header isAuthenticated={isAuthenticated} demoEnabled={demoEnabled} />

      { (isAuthenticated || demoEnabled) && <Sidebar /> }
    
      <main className={classes.main}>
        { showTrialButton && (
          <div style={{width: '100%'}}>
            <Button variant='contained' className={classes.infoBox} onClick={() => window.location = process.env.REACT_APP_FREE_TRIAL_URL}>
              START YOUR TWO MONTH FREE TRIAL
            </Button>
          </div>
        )}
        { (isAuthenticated || demoEnabled) && children }

        <GuideInfoModal title={"Welcome to our Demo Page!"} open={modalOpen} onClose={handleModalClose} closeModalProps={closeModalProps}>
          <div>
            <p>
              We're thrilled to have you here. Explore our latest version of STAR, the cutting-edge solution that's setting new standards in the industry. We've prepared four sets of project data to demonstrate the power of our Automated Defect Prediction tool across various cases, all powered by 3S. Feel free to test drive our features and read more about our tool from the menu bar.
            </p>
            <p>
              Enjoy your demo! If you have any questions or need assistance, <a href={process.env.REACT_APP_CONTACT_US_URL}>our support team</a> is just a click away.
            </p>
          </div>
        </GuideInfoModal>

        <InfoModal title="No Companies Registered" open={no_companies && accountModalOpen} onClose={handleAccountModalClose}>
          <div>
            <p>
              Looks like you don't have any companies tied to your account. 
            </p>
            <p>
              <a href={`${process.env.REACT_APP_LANDING_URL}/trial`}>Click here</a> to read more about our free trial
            </p>
          </div>
        </InfoModal>

        <InfoModal title="Company Membership Expired" open={company_expired && accountModalOpen} onClose={handleAccountModalClose}>
          <div>
            <p>
              Looks like your company's membership has expired
            </p>
            <p>
              <a href={`${process.env.REACT_APP_LANDING_URL}/account`}>Click here</a> to activate your subscription
            </p>
          </div>
        </InfoModal>
      </main>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.common.isAuthenticated,
    demoEnabled: state.common.demoEnabled,
    showTrialButton: state.common.company && state.common.company.id === 1,
    company_expired: state.common.company_expired,
    no_companies: state.common.no_companies,
    company: state.common.company,
  };
} 

export default connect(mapStateToProps)(Layout)