import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
  header: {
    float: 'left',
  },
  divider: {
    float: 'left',
    width: '100%',
    marginBottom: 8,
  },
}));


export default function ViewTitle(props) {
  const classes = useStyles();
  const { title, prediction_type, children } = props;

  return (
    <React.Fragment>
      <Typography className={classes.header} variant="h1">
        { title } 
        { prediction_type && (<i> ({ prediction_type }) </i>) }
      </Typography>
      { children }
      <Divider className={classes.divider} />
      <br />
    </React.Fragment>
  );
}