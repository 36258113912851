import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';


const useStyles = makeStyles({
  button: {
    width: 240,
  },
  icon: {
    fontSize: 18,
    paddingLeft: 4,
  }
});

export const SubmitButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { disabled, className, text } = props;

  const displayText = text || 'Save Config';

  const buttonProps = {
    color: "secondary",
    variant: "contained",
    type: 'submit',
    className: clsx(classes.button, className),
    disabled: disabled,
    ref: ref,
  };

  return (
    <Button {...buttonProps}>
      { displayText } <SaveIcon className={classes.icon} />
    </Button>
  );
});