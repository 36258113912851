import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { backgroundDark } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  infoButton: {
    padding: 0,
    marginTop: 2,
    marginLeft: 6,
    "&:hover": {
      background: backgroundDark,
      color: 'black',
    },
  }
}));

export default function InfoButton(props) {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <IconButton className={classes.infoButton} onClick={onClick}>
      <InfoSharpIcon fontSize='small' />
    </IconButton>
  )
};