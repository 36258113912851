import React from 'react';
import { connect } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ViewTitle from '../../common/layout/ViewTitle';

const axios = require('axios').default;

const columns = [
  {
    field: 'event',
    headerName: 'Event',
    width: 340,
    editable: false,
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 340,
    editable: false,
  },
  ];


function TrackerView(props) {
  const { jwtToken, baseApiUri } = props;

  const [users, setUsers] = React.useState([]);
  const [selectedEmail, setSelectedEmail] = React.useState();

  const [events, setEvents] = React.useState([]);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const headers = { Authorization: jwtToken };

    axios.get(`${baseApiUri}/user-events/users`, { headers })
      .then(response => {
        setUsers(response.data.users);
      })
      .catch(err => {
        setError(true);
      })
  }, []);

  React.useEffect(() => {
    if (selectedEmail) {
      const headers = { Authorization: jwtToken };

      axios.get(`${baseApiUri}/user-events/${selectedEmail}`, { headers })
        .then(response => {
          setEvents(response.data.events);
        })
        .catch(err => {
          setError(true);
        })
    }
  }, [selectedEmail]);

  return (
    <React.Fragment>
      <ViewTitle title='User Events'/>

      <Card sx={{ width: "100%", "padding": "24px" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={users}
          sx={{ width: "100%", "margin-bottom": "24px" }}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => <TextField {...params} label="User" />}
          onInputChange={(event, email) => {
            setSelectedEmail(email);
          }}
        />

        {events && (
          <Box sx={{ "height": "640px" }}>
            <DataGrid
              rows={events.map((event, idx) => ({ ...event, id: idx }))}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              // pageSizeOptions={[5]}
              autoPageSize
            />
          </Box>
        )}
      </Card>


    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
  };
} 

export default connect(mapStateToProps)(TrackerView)
