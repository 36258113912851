import React from 'react';
import DefectChart from '../../../common/data_display/DefectChart';
import { cumulateDatapoints, formatDatapoint, parseDate } from '../../../common/data_display/utils';
import { arrivalLineColor, overlayLineColor } from '../../../constants/colors';
import NivoChart from '../../../common/data_display/NivoChart';

export default function CumulativeChart(props) {
  const { arrival, stability, metrics, d1_date, d2_date, start_date } = props;

  const lines = [];
  const rects = [];
  const scatters = [];

  if (arrival && arrival.actual_processed && arrival.actual_processed.length > 0) {
    const arrivalActual = cumulateDatapoints(arrival.actual_processed).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Actual)', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'solid', legend: true });
  }

  if (arrival && arrival.predicted && arrival.predicted.length > 0) {
    const arrivalActual = cumulateDatapoints(arrival.predicted).map(obj => formatDatapoint(obj));
    lines.push({ id: 'Arrival (Predicted)', data: arrivalActual, color: arrivalLineColor, strokeStyle: 'dashed', legend: true });
  }

  if (stability && stability.p2 && stability.p2.length > 0) {
    const predictionStability = stability.p2.map(obj => formatDatapoint(obj));
    lines.push({ id: 'Predicted @ D2', data: predictionStability, color: 'blue', strokeStyle: 'solid', legend: true });
  }

  var targetValue = metrics.predicted_defects_d2; 
  var beginDate = arrival && arrival.actual_processed && arrival.actual_processed.length > 0 ? new Date(arrival.actual_processed[0].date) : undefined;
  var endDate = arrival && arrival.predicted && arrival.predicted.length > 0 ? new Date(arrival.predicted[arrival.predicted.length-1].date) : undefined;

  if (targetValue && beginDate && endDate && (metrics.stability_date === "Mon, 01 Jan 1900 00:00:00 GMT" || !metrics.stability_date)) {
    lines.push({
      id: 'Target (+/- 10%)',
      data: [
        { value: targetValue, date: beginDate },
        { value: targetValue, date: endDate },
      ],
      color: overlayLineColor,
      strokeStyle: 'dashed',
      legend: true
    });

    rects.push({
      id: 'Rect ~ Target +/-10% hidden',
      data: [
        { value: targetValue * 0.9, date: beginDate },
        { value: targetValue * 1.1, date: endDate }
      ],
      color: overlayLineColor,
      opacity: 0.1,
    });
  } else if (metrics.stability_date !== "Mon, 01 Jan 1900 00:00:00 GMT") {
    if (metrics.stability_upper_limit && metrics.stability_start_date) {
      lines.push({
        id: '+10% Limit',
        data: [
          { value: metrics.stability_upper_limit, date: new Date(metrics.stability_start_date) },
          { value: metrics.stability_upper_limit, date: parseDate(d2_date) },
        ],
        color: "#ffc001",
        strokeStyle: 'dashed',
        legend: true
      })
    }

    if (metrics.stability_lower_limit && metrics.stability_start_date) {
      lines.push({
        id: '-10% Limit',
        data: [
          { value: metrics.stability_lower_limit, date: new Date(metrics.stability_start_date) },
          { value: metrics.stability_lower_limit, date: parseDate(d2_date) },
        ],
        color: "black",
        strokeStyle: 'dashed',
        legend: true
      })
    }

    if (metrics.stability_defects && metrics.stability_start_date) {
      lines.push({
        id: 'Predicted Defects',
        data: [
          { value: metrics.stability_defects, date: new Date(metrics.stability_start_date) },
          { value: metrics.stability_defects, date: parseDate(d2_date) },
        ],
        color: "limegreen",
        strokeStyle: 'dashed',
        legend: true
      })
    }

    if (metrics.stability_defects && metrics.stability_date) {
      scatters.push({
        id: 'Stability Date',
        data: [
          { value: metrics.stability_defects, date: new Date(metrics.stability_date) },
        ],
        strokeStyle: 'solid',
        color: 'black',
        fill: 'red',
        points: true,
        legend: true
      })
    }
  }

  return <NivoChart 
    title='Predicted defects at D2' 
    chartTitle={(metrics.stability_date === "Mon, 01 Jan 1900 00:00:00 GMT" || !metrics.stability_date) ? 'Prediction is not stable yet' : ''}
    lines={lines}
    rects={rects}
    scatters={scatters}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)} 
    // start_date={parseDate(start_date)}
    chartHeight='28vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}
