import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const sendAlert = (alert) => {
    setAlerts((prev) => [...prev, alert]);
  };

  const removeAlert = () => {
    setAlerts((prev) => prev.slice(1));
  };

  return (
    <AlertContext.Provider value={{ sendAlert, removeAlert, alerts }}>
      {children}
    </AlertContext.Provider>
  );
};
