import React from 'react';
import EffortChart from '../../../common/data_display/EffortChart';
import { formatDatapoint, parseDate } from '../../../common/data_display/utils';
import NivoChart from '../../../common/data_display/NivoChart';
import { max } from 'date-fns';

export default function EffortDataChart(props) {
  const { title, items, release_name, d1_date, d2_date, prev_d1_date, prev_d2_date, yAxis2, yAxis2Titles, start_date } = props;

  const dateDifftoDays = (date1, date2) => {
    // 86400000 conversion for milli-seconds to days
    return (date1 - date2)/86400000;
  }

  const calc_max_yaxis2 = (effort, defects) => {
    let last_defect = 0;
    const lastDefectDate = (defects[defects.length-1]["date"] > parseDate(d2_date)) ? parseDate(d2_date) : defects[defects.length-1]["date"];

    if (defects[defects.length-1]["date"] > parseDate(d2_date)) {
      for (let i=0; i < defects.length; ++i) {
        if (lastDefectDate < defects[i]["date"]) {
          last_defect = defects[i]["value"];
          break;
        }
      }
    } else { 
      last_defect = defects[defects.length-1]["value"];
    }

    let effort_at_lastdefect = 0;
    for (let i=0; i < effort.length; ++i) {
      if (i != effort.length-1) {
        if ((effort[i]["date"] < lastDefectDate) && (lastDefectDate < effort[i+1]["date"])) {
          effort_at_lastdefect = (effort[i]["value"]) + (((effort[i+1]["value"] - effort[i]["value"])/dateDifftoDays(effort[i+1]["date"], effort[i]["date"])) * dateDifftoDays(lastDefectDate, effort[i]["date"]));
          break;
        }
      }
    }

    return Math.ceil((Math.ceil(Math.max(...effort.map(item => item.value))) / effort_at_lastdefect) * last_defect);
  }

  function scaleData(lines, leftTitle, rightTitle) {
    if (lines.length != 2) return [null, null];

    const maxYLine1 = Math.max(Math.max(...lines[0].data.map(item => item.value)), lines[0].yDomain[1]);
    const maxYLine2 = Math.max(Math.max(...lines[1].data.map(item => item.value)), lines[1].yDomain[1]);
    const maxY = Math.max(maxYLine1, maxYLine2);

    let scaledData = [];
    if (maxYLine1 < maxY) {
      const scale = maxY/maxYLine1;
      for (let i=0; i < lines[0].data.length; ++i) {
        scaledData.push({date: lines[0].data[i].date, value: lines[0].data[i].value * scale})
      }
      return [[{ id: lines[0].id, yDomain: lines[0].yDomain, data: scaledData, color: lines[0].color, strokeStyle: lines[0].strokeStyle, legend: lines[0].legend }, lines[1]], scale, leftTitle == lines[0].id ? "left" : "right"];
    } else if (maxYLine2 < maxY) {
      const scale = maxY/maxYLine2;
      for (let i=0; i < lines[1].data.length; ++i) {
        scaledData.push({date: lines[1].data[i].date, value: lines[1].data[i].value * scale});
      }   
      return [[lines[0], { id: lines[1].id, yDomain: lines[1].yDomain, data: scaledData, color: lines[1].color, strokeStyle: lines[1].strokeStyle, legend: lines[1].legend }], scale, leftTitle == lines[1].id ? "left" : "right"];
    }
  }

  const current_data = [];
  const previous_data = [];
  const lines = [];
  const colors = ['yellow', 'pink', 'purple', 'green', 'blue', 'red'];
  const yAxis2Data = yAxis2 ? {left: yAxis2Titles[0], right: yAxis2Titles[1]} : {};

  for (const [key, data] of Object.entries(items)) {
    const color = colors.pop();

    if (data) {
      if (data.length > 0) {
        const valueKey = Object.keys(data[0])[1];
        for (let i=0; i < data.length; ++i) {
          if (key == "current") {
            current_data.push({date: data[i]["date"], value: data[i][valueKey]});
          } else if (key == "previous") {
            previous_data.push({date: data[i]["date"] ? parseDate(data[i]["date"]) : data[i]["date"], value: data[i][valueKey]});
          }
        }

        if (key == "current") {
          if (yAxis2) {
            lines.push({ id: release_name[key], yDomain: [0, Math.max(...current_data.map(item => item.value))], data: current_data.map(obj => formatDatapoint(obj)), color: color, strokeStyle: 'solid', legend: true });
          } else {
            lines.push({ id: release_name[key], data: current_data.map(obj => formatDatapoint(obj)), color: color, strokeStyle: 'solid', legend: true });
          }
        } else if (key == "previous") {
          if (yAxis2) {
            lines.push({ id: release_name[key], yDomain: [0, calc_max_yaxis2(current_data, previous_data)], data: previous_data, color: color, strokeStyle: 'solid', legend: true });
          } else {
            lines.push({ id: release_name[key], data: previous_data, color: color, strokeStyle: 'solid', legend: true });
          }
        }
      }
    }
  }

  if (yAxis2) {
    const [scaledLines, scale, scaleSide] = scaleData(lines, yAxis2Titles[0], yAxis2Titles[1]);
    const yAxis2Data = lines.length > 1 ? {left: yAxis2Titles[0], right: yAxis2Titles[1], leftDomain: lines[0].yDomain, rightDomain: lines[1].yDomain, scale: scale, scaleSide: scaleSide} : null; 

    return <NivoChart 
      title={title}
      lines={scaledLines ? scaledLines : lines}
      d1_date={parseDate(d1_date)} 
      d2_date={parseDate(d2_date)}
      delayed_D1={prev_d1_date ? parseDate(prev_d1_date) : undefined}
      delayed_D2={prev_d2_date ? parseDate(prev_d2_date) : undefined}
      start_date={parseDate(start_date)}
      yAxis2={yAxis2 ? yAxis2Data : undefined}
      chartHeight='32vw'
      allowHidingLines={true}
      allowHoverTooltip={true}
    />
  }

  return <NivoChart 
    title={title}
    lines={lines}
    d1_date={parseDate(d1_date)} 
    d2_date={parseDate(d2_date)}
    delayed_D1={prev_d1_date ? parseDate(prev_d1_date) : undefined}
    delayed_D2={prev_d2_date ? parseDate(prev_d2_date) : undefined}
    start_date={parseDate(start_date)}
    yAxis2={yAxis2 ? yAxis2Data : undefined}
    chartHeight='20vw'
    allowHidingLines={true}
    allowHoverTooltip={true}
  />
}