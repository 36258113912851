import ExecutiveView from './executive_view/main';
import CorrectiveActionsView from './corrective_actions_view/main';
import ArrivalClosureView from './arrival_closure_view/main';
import PredictionStability from './prediction_stability_view/main';
import EarlyPredictionView from './early_prediction_view/main';
import ByItemView from './by_item/main';
import ReleaseOverReleaseView from './release_over_release/main';
import ByComponentConfig from './by_item/configs/ByComponentConfig';
import BySeverityConfig from './by_item/configs/BySeverityConfig';
import EffortDataViewPPM from './effort_data_view/ppm_effort_view/main';
import EffortDataViewIPM from './effort_data_view/ipm_effort_view/main';
import CustomerDefectData from './customer_defect_data/main';

import store from '../../store';
import { showDefectFilters, hideDefectFilters, showReleaseSelector, hideReleaseSelector } from '../../common/redux/actions';

const renderWithFilter = (Component) => () => {
  store.dispatch(showDefectFilters());
  store.dispatch(showReleaseSelector());
  return <Component />
}

const renderWithoutFilter = (Component) => () => {
  store.dispatch(hideDefectFilters());
  store.dispatch(showReleaseSelector());
  return <Component />
}

const renderWithoutRelease = (Component) => () => {
  store.dispatch(hideDefectFilters());
  store.dispatch(hideReleaseSelector());
  return <Component />
}

// -------------------------------- Unified -------------------------------- //
// ------------------------------------------------------------------------- //
const getUnifiedUrl = (r) => `/releases/${r.id}/reports`;

export const unifiedExecutivePath = '/executive-summary';

export const unifiedRoutes = [
  { name: 'Executive Summary', path: unifiedExecutivePath, render: renderWithFilter(() => <ExecutiveView getUrl={getUnifiedUrl} />) },
];

// ---------------------------------- MPM ---------------------------------- //
// ------------------------------------------------------------------------- //
const getMpmUrl = (r) => `/releases/${r.id}/reports/mpm`;

export const mpmExecutivePath = '/mpm/executive-summary';
const mpmArrivalClosurePath = '/mpm/arrival-closure';
const mpmPredictionStabilityPath = '/mpm/prediction-stability';
const mpmCorrectiveActionsPath = '/mpm/corrective-actions';
const mpmDefectsByComponentPath = '/mpm/defects-by-component';
const mpmDefectsBySeverityPath = '/mpm/defects-by-severity';
const mpmReleaseOverReleasePath = '/mpm/release-over-release';

export const mpmRoutes = [
  { name: 'Executive Summary', path: mpmExecutivePath, render: renderWithFilter(() => <ExecutiveView getUrl={getMpmUrl} />) },
  { name: 'Corrective Actions', path: mpmCorrectiveActionsPath, render: renderWithFilter(() => <CorrectiveActionsView getUrl={getMpmUrl} />) },
  { name: 'Arrival / Closure', path: mpmArrivalClosurePath, render: renderWithFilter(() => <ArrivalClosureView getUrl={getMpmUrl} />) },
  { name: 'Defects by Component', path: mpmDefectsByComponentPath, render: renderWithoutFilter(() => <ByItemView getUrl={getMpmUrl} byItemConfig={ByComponentConfig()} />) },
  { name: 'Defects by Severity', path: mpmDefectsBySeverityPath, render: renderWithoutFilter(() => <ByItemView getUrl={getMpmUrl} byItemConfig={BySeverityConfig()} />) },
  { name: 'Release-Over-Release', path: mpmReleaseOverReleasePath, render: renderWithoutRelease(() => <ReleaseOverReleaseView getUrl={getMpmUrl} />) },
  { name: 'Prediction Stability', path: mpmPredictionStabilityPath, render: renderWithFilter(() => <PredictionStability getUrl={getMpmUrl} />) },
];

// ---------------------------------- PPM ---------------------------------- //
// ------------------------------------------------------------------------- //
const getPpmUrl = (r) => `/releases/${r.id}/reports/ppm`;

const ppmExecutivePath = '/ppm/executive-summary';
const ppmEarlyPredictionPath = '/ppm/early-prediction';
const ppmCorrectiveActionsPath = '/ppm/corrective-actions';
const ppmEffortDataPath = '/ppm/effort-data';

export const ppmRoutes = [
  { name: 'Executive Summary', path: ppmExecutivePath, render: renderWithoutFilter(() => <ExecutiveView getUrl={getPpmUrl} warnOnError={true} />) },
  { name: 'Corrective Actions', path: ppmCorrectiveActionsPath, render: renderWithFilter(() => <CorrectiveActionsView getUrl={getPpmUrl} warnOnError={true} />) },
  { name: 'Early Prediction', path: ppmEarlyPredictionPath, render: renderWithoutFilter(() => <EarlyPredictionView getUrl={getPpmUrl} warnOnError={true} />) },
  { name: 'Effort Data', path: ppmEffortDataPath, render: renderWithoutFilter(() => <EffortDataViewPPM getUrl={getIpmUrl} warnOnError={true} />) },
];

// ---------------------------------- IPM ---------------------------------- //
// ------------------------------------------------------------------------- //
const getIpmUrl = (r) => `/releases/${r.id}/reports/ipm`;

const ipmExecutivePath = '/ipm/executive-summary';
const ipmEarlyPredictionPath = '/ipm/early-prediction';
const ipmPredictionStabilityPath = '/ipm/prediction-stability';
const ipmCorrectiveActionsPath = '/ipm/corrective-actions';
const ipmEffortDataPath = '/ipm/effort-data';

export const ipmRoutes = [
  { name: 'Executive Summary', path: ipmExecutivePath, render: renderWithoutFilter(() => <ExecutiveView getUrl={getIpmUrl} warnOnError={true} />) },
  { name: 'Corrective Actions', path: ipmCorrectiveActionsPath, render: renderWithFilter(() => <CorrectiveActionsView getUrl={getIpmUrl} warnOnError={true} />) },
  { name: 'Early Prediction', path: ipmEarlyPredictionPath, render: renderWithoutFilter(() => <EarlyPredictionView getUrl={getIpmUrl} warnOnError={true} />) },
  { name: 'Prediction Stability', path: ipmPredictionStabilityPath, render: renderWithFilter(() => <PredictionStability getUrl={getIpmUrl} warnOnError={true} />) },
  { name: 'Effort Data', path: ipmEffortDataPath, render: renderWithoutFilter(() => <EffortDataViewIPM getUrl={getIpmUrl} warnOnError={true} />) },
];


// ---------------------------------- IPM ---------------------------------- //
// ------------------------------------------------------------------------- //
const getPrmUrl = (r) => `/releases/${r.id}/reports/prm`;

const prmExecutivePath = '/prm/executive-summary';
const prmCorrectiveActionsPath = '/prm/corrective-actions';
const prmPredictionStabilityPath = '/prm/prediction-stability';

export const prmRoutes = [
  { name: 'Executive Summary', path: prmExecutivePath, render: renderWithoutFilter(() => <ExecutiveView getUrl={getPrmUrl} warnOnError={true} />) },
  { name: 'Corrective Actions', path: prmCorrectiveActionsPath, render: renderWithFilter(() => <CorrectiveActionsView getUrl={getPrmUrl} warnOnError={true} />) },
  { name: 'Prediction Stability', path: prmPredictionStabilityPath, render: renderWithFilter(() => <PredictionStability getUrl={getPrmUrl} warnOnError={true} />) },
];


// ---------------------------------- IPM ---------------------------------- //
// ------------------------------------------------------------------------- //
const getLpmUrl = (r) => `/releases/${r.id}/reports/lpm`;

const lpmDefectDataPath = '/lpm/customer-defect-data';

export const lpmRoutes = [
  { name: 'Customer Defect Data', path: lpmDefectDataPath, render: renderWithoutFilter(() => <CustomerDefectData getUrl={getLpmUrl} warnOnError={true} />) },
];
