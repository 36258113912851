import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDateTime } from '../../common/data_display/utils';
import ReportError from './ReportError';
const moment = require('moment');

// If prediction is still running after this time, consider it failed.
const TIME_LIMIT = 15 * 60;

export default function ReportRunning(props) {
  const { timestamp } = props;

  if (timestamp) {
    const startDate = moment.utc(timestamp)
    const currentDate = moment.utc()

    if ((currentDate.unix() - startDate.unix()) >= TIME_LIMIT) {
      return <ReportError error="Timeout" />
    }
    
  }

  return (
    <div style={{ borderRadius: 8, color: 'grey', maxWidth: 800, padding: 24, margin: '0 auto', marginTop: '24px', textAlign: 'center' }}>
      <CircularProgress />

      <Typography style={{ fontSize: 24 }}>
        Prediction In Progress 
        { timestamp && ` (${formatDateTime(timestamp)})` }
      </Typography>
   </div>
  )
}
