import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { setCompany } from '../../redux/actions';
import { darkGrey } from '../../../constants/colors';
import store from '../../../store';


const useStyles = makeStyles((theme) => ({
  formControl: {
    background: 'white',
    minWidth: 180,
    marginRight: 8,
    border: `1px solid ${darkGrey}`,
    borderRadius: 4,
  },
  selectRoot: {
    height: 22,
    lineHeight: '22px',
    padding: 0,
    paddingLeft: 12,
    fontSize: 12,
    fontWeight: 'bold',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
}));


export default function CompanySelect(props) {
  const { company, companyList } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;

    if (company.id !== value.id) {
      store.dispatch(setCompany(value));
    }
  }

  const selectProps = {
    value: company,
    disableUnderline: true,
    onChange: handleChange,
    classes: {
      root: classes.selectRoot,
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select {...selectProps}>
        {
          companyList.map((item) => (
            <MenuItem value={item}>
              {item.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
