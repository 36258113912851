import './index.css';
import '../node_modules/react-vis/dist/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import { AlertProvider } from './common/data_display/alerts/AlertContext';
import GlobalSnackbar from './common/data_display/alerts/GlobalSnackbar';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {primaryMain, secondaryMain, background } from './constants/colors';

import store from './store';
import App from './App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
    },
    background: {
      default: background,
    },
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: 18,
    },
    h3: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 10,
      fontWeight: 'bold',
    }
  },
  overrides: {
    MuiCardContent: {
      root: {
        background: 'white',
        padding: 12,
        paddingTop: 16,
        "&:last-child": {
          paddingBottom: 12,
       },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>      
        <BrowserRouter>
          <AlertProvider>
            <GlobalSnackbar />
            <App />
          </AlertProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
