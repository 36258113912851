import React from 'react';
import { NumberInput } from '../fields';




export default function CcpmNested(props) {
  const { config, onChange, disabled } = props;

  const fields = [
    { label: 'Delay Step', value: config.delay_step, onChange: onChange('delay_step') },
    { label: 'Delay Initial', value: config.delay_initial, onChange: onChange('delay_initial') },
    { label: 'Iteration Limit', value: config.iteration_limit, onChange: onChange('iteration_limit') },
    { label: 'SSQ (Final N Points)', value: config.ssq_final_n_points, onChange: onChange('ssq_final_n_points') },
  ];

  return (
   <React.Fragment>
    { fields.map((field) => <NumberInput disabled={disabled} required={true} {...field} />) }
   </React.Fragment>
  )
}
