import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ReportError from '../../misc/ReportError';
import BackendError from '../../misc/BackendError';
import ReportNotFound from '../../misc/ReportNotFound';
import ViewTitle from '../../../common/layout/ViewTitle';
import InputTable from './InputTable';
import OutputTable from './OutputTable';
import CumulativeChart from './CumulativeChart';
import BacklogChart from './BacklogChart';
import InfoButton from '../../../common/data_display/InfoButton';
import CorrectiveActionsInfoModal from './CorrectiveActionsInfoModal.js'

const axios = require('axios').default;

function ExecutiveView(props) {
  const { release, jwtToken, baseApiUri, getUrl, filtersEnabled, componentFilter, severityFilter, warnOnError } = props;

  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [report, setReport] = React.useState();
  const [correctiveAction, setCorrectiveAction] = React.useState();
  const defaultInputValue = {
    delayValue: 0,
    developersValue: 0,
    testersValue: 0,
    contentValue: 0,
  };

  const [inputValue, setInputValue] = React.useState(defaultInputValue);
  const [searchInputValue, setSearchInputValue] = React.useState(defaultInputValue);

  const [modalOpen, setModalOpen] = React.useState(false);

  const [reportError, setReportError] = React.useState(false);
  const [backendError, setBackendError] = React.useState(false);
  const [reportNotFound, setReportNotFound] = React.useState(false);

  const componentIds = filtersEnabled ? componentFilter.join(',') : undefined;
  const severityIds = filtersEnabled ? severityFilter.join(',') : undefined;

  const getCorrectiveAction = () => {
    setCorrectiveAction(undefined);

    const headers = { Authorization: jwtToken };

    const url = `${baseApiUri}${getUrl(release)}/corrective-actions`;

    setSearchInputValue(inputValue);

    const params = {
      pct_increase_testers: inputValue.testersValue,
      pct_increase_developers: inputValue.developersValue,
      pct_content_reduction: inputValue.contentValue,
      delay_in_weeks: inputValue.delayValue,
    };

    if (filtersEnabled) {
      params.component_ids = componentIds;
      params.severity_ids = severityIds;
    }

    return axios.get(url, { headers, params })
      .then(response => {
        setCorrectiveAction(response.data);
      })
      .catch(err => {})
  };


  // --------------------------- Lifecycle Hooks --------------------------- //
  // ----------------------------------------------------------------------- //
  React.useEffect(() => {
    if (release) {
      setReport(undefined);
      setBackendError(false);
      setReportNotFound(false);
      setInputValue(defaultInputValue);

      const params = filtersEnabled ? { 
        component_ids: componentIds, 
        severity_ids: severityIds,
      } : {};

      const headers = { Authorization: jwtToken };

      return axios.get(`${baseApiUri}${getUrl(release)}`, { params, headers })
        .then(response => {
          setReport(response.data);
          setReportError(response.data.error);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setReportNotFound(true);
          }
          else if (err.response.status >= 500) {
            setBackendError(true);
          }
        })
        .then(() => {
          getCorrectiveAction();
        })
    }
  }, [release, getUrl, filtersEnabled, componentIds, severityIds, jwtToken, baseApiUri]);


  // --------------------------- Event Handlers ---------------------------- //
  // ----------------------------------------------------------------------- //
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleInputValueChange = (partialValue) => {
    setInputValue({
      ...inputValue,
      ...partialValue,
    });
  }

  const handleInputValueChangeCommitted = () =>{
    getCorrectiveAction();
  }

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (reportError) return <ReportError error={report ? report.error : ''} warnOnError={warnOnError} />
  if (backendError) return <BackendError />
  if (reportNotFound) return <ReportNotFound />

  return (
    <React.Fragment>
      <ViewTitle title='Corrective Actions'>
        <InfoButton onClick={handleModalOpen} />
      </ViewTitle>
      {
        report && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <InputTable
                value={inputValue}
                onValueChange={handleInputValueChange}
                onValueChangeCommitted={handleInputValueChangeCommitted}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {correctiveAction && <OutputTable metrics={report.metrics} correctiveAction={correctiveAction} />}
            </Grid>
            <Grid item xs={12} md={6}>
              <CumulativeChart {...report} {...correctiveAction} inputValue={searchInputValue} />
            </Grid>
            <Grid item xs={12} md={6}>
              <BacklogChart {...report} {...correctiveAction} inputValue={searchInputValue} />
            </Grid>
          </Grid>
        )
      }

      <CorrectiveActionsInfoModal open={modalOpen} onClose={handleModalClose} />
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    release: state.common.release,
    componentFilter: state.common.componentFilter,
    severityFilter: state.common.severityFilter,
    jwtToken: state.common.jwtToken,
    baseApiUri: state.common.baseApiUri,
    filtersEnabled: state.common.showDefectFilter,
  };
} 

export default connect(mapStateToProps)(ExecutiveView)
