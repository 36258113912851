import React, { useEffect, useState, useRef } from 'react';
import { useAlert } from './AlertContext';

import { Snackbar } from '@mui/base/Snackbar';
import { Transition } from 'react-transition-group';
import Fade from '@mui/material/Fade';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

import { styled as mui_styled } from '@mui/system';
import styled from '@emotion/styled'
import { secondaryMain } from '../../../constants/colors';

const StyledSnackbar = mui_styled(Snackbar)({
    zIndex: 5000,
    maxWidth: "560px",
    minWidth: "300px",
    display: "flex",
    position: "fixed",
    top: "5rem",
    right: "16px",
});

const StyledSnackbar2 = mui_styled(Snackbar)({
  zIndex: 5000,
  maxWidth: "560px",
  minWidth: "300px",
  display: "flex",
  position: "fixed",
  transform: 'translateX(-50%)',
  top: "20%",
  left: "50%",
});

const StyledSnackbarContent = styled.div(({ severity }) => ({
    display: 'flex',
    gap: '8px',
    overflow: 'hidden',
    backgroundColor: getBackgroundColor(severity),
    borderRadius: '5px',
    boxShadow: '0 2px 16px rgba(0,0,0,0.25)',
    padding: '0.75rem',
    color: '#1C2025',
    textAlign: 'start',
    position: 'relative',

    '& .snackbar-message': {
        flex: '1 1 0%',
        maxWidth: '100%',
    },

    '& .snackbar-title': {
        margin: 0,
        marginRight: '0.5rem',
        paddingBottom: 2,
        fontSize: '0.9rem',
        color: severity === "success" ? "#013220" : secondaryMain,
    },
    
    '& .snackbar-description': {
        margin: 0,
        lineHeight: '1.5rem',
        color: "#000",
    },
    
    '& .snackbar-close-icon': {
        cursor: 'pointer',
        flexShrink: 0,
        padding: '2px',
        borderRadius: '4px',
    
        '&:hover': {
          background: '#eff2f6',
        },
    },
}));

const positioningStyles = {
    entering: 'translateX(-22px)',
    entered: 'translateX(-22px)',
    exiting: 'translateX(600px)',
    exited: 'translateX(600px)',
    unmounted: 'translateX(600px)',
};

const getBackgroundColor = (severity) => {
  switch (severity) {
    case 'error':
      return 'rgb(219, 187, 191)';
    case 'success':
      return 'rgb(199, 219, 205)';
    case 'info':
    default:
      return 'white';
  }
};

export default function GlobalSnackbar() {
  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const { alerts, removeAlert } = useAlert();
  const [open, setOpen] = useState(false);
  const [exited, setExited] = useState(true);
  const nodeRef = useRef(null);

  // --------------------------- Event Handlers ---------------------------- //
  // ----------------------------------------------------------------------- // 
  useEffect(() => {
    if (!open && exited && alerts.length > 0) {
      setOpen(true);
    }
  }, [alerts, open]);

  const handleClose = (event, reason) => {
    if (reason && reason === 'clickaway') {
        return;
    }
    setOpen(false);
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
    removeAlert();
  };

  // ------------------------------- Render -------------------------------- //
  // ----------------------------------------------------------------------- //
  if (alerts[0]?.type === 'slider') {
    return (
      <React.Fragment>
          {alerts.length > 0 && (
              <StyledSnackbar
              id={alerts[0].id || uuidv4()}
              style={alerts[0].style || {}}
              open={open}
              onClose={handleClose}
              exited={exited}
              autoHideDuration={(alerts[0].duration * 1000) || 10000}
              >
                  <Transition 
                  timeout={{ enter: 400, exit: 400 }}
                  in={open}
                  onEnter={handleOnEnter}
                  onExited={handleOnExited}
                  nodeRef={nodeRef}
                  appear
                  unmountOnExit
                  >
                      {(transitionState) => (
                          <StyledSnackbarContent
                              severity={alerts[0].severity || "info"}
                              style={{
                                  transform: positioningStyles[transitionState],
                                  transition: 'transform 300ms ease',
                              }}
                              ref={nodeRef}
                          >
                              <div className="snackbar-message">
                                  <Typography className="snackbar-title" gutterBottom variant="h3">
                                    {alerts[0].title || ""}
                                  </Typography>
                                  <Divider />
                                  <Typography className="snackbar-description">
                                    {alerts[0].message || ""}
                                  </Typography>
                              </div>
                              <CloseIcon onClick={handleClose} className="snackbar-close-icon" />
                          </StyledSnackbarContent>
                      )}
                  </Transition>
              </StyledSnackbar>
          )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
          {alerts.length > 0 && (
              <StyledSnackbar2
              id={alerts[0].id || uuidv4()}
              style={alerts[0].style || {}}
              open={open}
              onClose={handleClose}
              exited={exited}
              autoHideDuration={(alerts[0].duration * 1000) || 10000}
              >
                  <Fade 
                  timeout={{ enter: 400, exit: 400 }}
                  in={open} 
                  onEnter={handleOnEnter} 
                  onExited={handleOnExited}
                  nodeRef={nodeRef}
                  >
                      <StyledSnackbarContent severity={alerts[0].severity || "info"} ref={nodeRef}>
                          <div className="snackbar-message">
                              <Typography className="snackbar-title" gutterBottom variant="h3">
                                {alerts[0].title || ""}
                              </Typography>
                              <Divider />
                              <Typography className="snackbar-description">
                                {alerts[0].message || ""}
                              </Typography>
                          </div>
                          <CloseIcon onClick={handleClose} className="snackbar-close-icon" />
                      </StyledSnackbarContent>  
                  </Fade>
              </StyledSnackbar2>
          )}
      </React.Fragment>
    );  
  }
}
